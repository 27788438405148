import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './AddRecipients.css';

const getRandomColor = () => {
  const letters = '01234567'; // Restrict to darker shades (0-7)
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
};

const AddRecipients = () => {
  const location = useLocation();
  const { file, pdfLink } = location.state || {};
  const [recipients, setRecipients] = useState([]);
  const [documentName, setDocumentName] = useState(file ? file.name : '');
  const [showPopup, setShowPopup] = useState(false);
  const [recipientDetails, setRecipientDetails] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [mainSearchText, setMainSearchText] = useState(''); // Main search input value
  const [mainSearchSuggestions, setMainSearchSuggestions] = useState([]); // Suggestions for the main search
  const [disabledButton, setDisabledButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const pastRecipients = JSON.parse(localStorage.getItem('pastRecipients')) || [];
    const uniqueRecipients = [...new Map(pastRecipients.map((item) => [item.email, item])).values()];
    setMainSearchSuggestions(uniqueRecipients); // Populate the main search suggestions with unique entries
  }, []);

  const handleAddRecipientClick = () => {
    setShowPopup(true);
  };

  const handleSaveRecipient = () => {
    const { name, email, phoneNumber } = recipientDetails;
  
    if (email === "gorillahashcompany@gmail.com") {
      alert("This Email is not allowed.");
      return;
    }
  
    if (!name || !email || !phoneNumber) {
      alert("All fields are required.");
      return;
    }
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email.");
      return;
    }
  
    if (recipients.some((recipient) => recipient.email === email)) {
      alert("Recipient with this email already exists.");
      return;
    }
  
    const pastRecipients = JSON.parse(localStorage.getItem("pastRecipients")) || [];
    const recipientExists = pastRecipients.some(
      (recipient) => recipient.name === name && recipient.email === email
    );
  
    if (recipientExists) {
      setErrorMessage("Recipient is already created");
      return;
    }
  
    const newRecipient = { name, email, phoneNumber, color: getRandomColor() };
    setRecipients([...recipients, newRecipient]);
  
    const updatedPastRecipients = [
      ...new Map([...pastRecipients, newRecipient].map((item) => [item.email, item])).values(),
    ];
    localStorage.setItem("pastRecipients", JSON.stringify(updatedPastRecipients));
  
    setRecipientDetails({ name: "", email: "", phoneNumber: "" });
    setShowPopup(false);
  };
  

  const handleRemoveRecipient = (email) => {
    setRecipients(recipients.filter((recipient) => recipient.email !== email));
  };

  const handleContinue = () => {
    if (documentName.trim() && recipients.length > 0) {
      navigate('/sign', { state: { documentName, recipients, file, pdfLink } });
    }
  };

  const handleRecipientChange = (e) => {
    const { name, value } = e.target;
  
    setRecipientDetails({
      ...recipientDetails,
      [name]: value,
    });
  
    if (name === "email" && value === "gorillahashcompany@gmail.com") {
      setErrorMessage("This Email is not allowed");
      setDisabledButton(true); // Ensure the button remains disabled
    } else if (name === "email") {
      setErrorMessage("");
      setDisabledButton(false); // Re-enable only when email changes to something else
    }
  };
  

  // Autocomplete suggestions for the main search input
  const handleMainSearchChange = (e) => {
    const value = e.target.value;
    setMainSearchText(value);
    const pastRecipients = JSON.parse(localStorage.getItem('pastRecipients')) || [];
    const uniqueSuggestions = [...new Map(pastRecipients.map((item) => [item.email, item])).values()];
    const suggestions = uniqueSuggestions.filter((recipient) =>
      (recipient.email.includes(value) || recipient.name.includes(value)) &&
      !recipients.some((r) => r.email === recipient.email) // Exclude already added recipients
    );
    setMainSearchSuggestions(suggestions);
  };

  const handleSelectMainSuggestion = (suggestion) => {
    if (!recipients.some((recipient) => recipient.email === suggestion.email)) {
      setRecipients([...recipients, { ...suggestion, color: getRandomColor() }]);
    }
    setMainSearchText('');
    setMainSearchSuggestions([]);
  };

  return (
    <div className="add-recipients-container">
      <h2>Add Recipients</h2>
      <input
        type="text"
        className="input-field"
        placeholder="Rename Document"
        value={documentName}
        onChange={(e) => setDocumentName(e.target.value)}
      />
      <div className="recipient-search-wrapper">
        <input
          type="text"
          className="recipient-search"
          placeholder="Start typing name, email or group"
          value={mainSearchText}
          onChange={handleMainSearchChange}
        />
        <button className="add-button" onClick={handleAddRecipientClick}>
          + Add Recipient
        </button>
        {/* Display main search suggestions */}
        {mainSearchText && mainSearchSuggestions.length > 0 && (
          <ul className="suggestions-list main-search-suggestions">
            {mainSearchSuggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSelectMainSuggestion(suggestion)}>
                {suggestion.name} - {suggestion.email}
              </li>
            ))}
          </ul>
        )}
      </div>
      {recipients.length > 0 && (
        <ul className="recipients-list">
          {recipients.map((recipient, index) => (
            <li key={index} className="recipient-item" style={{ backgroundColor: recipient.color }}>
              {recipient.name} - {recipient.email} - {recipient.phoneNumber}
              <button onClick={() => handleRemoveRecipient(recipient.email)} className="remove-button">
                Remove
              </button>
            </li>
          ))}
        </ul>
      )}
      <button
        className="continue-button"
        onClick={handleContinue}
        disabled={!documentName || recipients.length === 0}
      >
        Continue
      </button>
      {showPopup && (
        <div className="add-recipient-popup">
          <h3>Add Recipient</h3>
          <input
            type="text"
            name="name"
            placeholder="Enter recipient name"
            value={recipientDetails.name}
            onChange={handleRecipientChange}
            className="input-field"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Enter recipient email"
            value={recipientDetails.email}
            onChange={handleRecipientChange}
            className="input-field"
            required
          />
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Enter phone number"
            value={recipientDetails.phoneNumber}
            onChange={handleRecipientChange}
            className="input-field"
            required
          />
          {errorMessage && (<p style={{ fontSize: "14px", color: "red" }}>{errorMessage}</p>)}

          <button disabled={disabledButton} onClick={handleSaveRecipient} className="save-button">
            Save
          </button>
          <button
            onClick={() => {
              setShowPopup(false);
              setRecipientDetails("");
              setErrorMessage("");
            }}
            className="cancel-button"
          >
            Cancel
          </button>

          {/* Display filtered suggestions in the popup */}
          {recipientDetails.email && filteredSuggestions.length > 0 && (
            <ul className="suggestions-list popup-suggestions">
              {filteredSuggestions.map((suggestion, index) => (
                <li key={index} onClick={() => setRecipientDetails({ ...recipientDetails, email: suggestion.email })}>
                  {suggestion.name} - {suggestion.email}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default AddRecipients;
