import React, { useRef, useState, useEffect } from "react";

const Test = () => {
    const viewerRef = useRef(null);
    const [signatures, setSignatures] = useState([]);

    const addSignature = () => {
        if (!viewerRef.current) {
            alert("Viewer is not ready!");
            return;
        }

        const container = viewerRef.current;
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        // Generate random positions as percentages of container dimensions
        const randomLeftRatio = Math.random() * (1 - 186 / containerWidth);
        const randomTopRatio = Math.random() * (1 - 74 / containerHeight);

        const signature = {
            id: crypto.randomUUID(),
            leftRatio: randomLeftRatio, // Store as a ratio
            topRatio: randomTopRatio, // Store as a ratio
            signatureText: "Signature",
        };

        setSignatures((prevSignatures) => [...prevSignatures, signature]);

        const createSignatureElement = (signatureData) => {
            const signatureElement = document.createElement("div");
            signatureElement.setAttribute("data-id", signatureData.id);
            signatureElement.style.position = "absolute";
            signatureElement.style.width = "15vw";
            signatureElement.style.height = "5vw";
            signatureElement.style.border = "2px solid blue";
            signatureElement.style.backgroundColor = "rgba(0, 0, 255, 0.5)";
            signatureElement.style.color = "white";
            signatureElement.style.textAlign = "center";
            signatureElement.style.lineHeight = "74px";
            signatureElement.style.borderRadius = "5px";
            signatureElement.style.cursor = "pointer";
            signatureElement.innerText = signatureData.signatureText;

            // Calculate initial positions in pixels
            const left = signatureData.leftRatio * container.offsetWidth;
            const top = signatureData.topRatio * container.offsetHeight;
            signatureElement.style.left = `${left}px`;
            signatureElement.style.top = `${top}px`;

            container.appendChild(signatureElement);
            return signatureElement;
        };

        createSignatureElement(signature);
    };

    // Update signature positions on resize
    useEffect(() => {
        const handleResize = () => {
            if (!viewerRef.current) return;

            const container = viewerRef.current;

            document.querySelectorAll("[data-id]").forEach((signatureElement) => {
                const id = signatureElement.getAttribute("data-id");
                const signatureData = signatures.find((sig) => sig.id === id);

                if (signatureData) {
                    // Recalculate positions
                    const left = signatureData.leftRatio * container.offsetWidth;
                    const top = signatureData.topRatio * container.offsetHeight;
                    signatureElement.style.left = `${left}px`;
                    signatureElement.style.top = `${top}px`;
                }
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [signatures]);

    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div
                style={{
                    width: "70%",
                    height: "600px",
                    backgroundColor: "red",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <div ref={viewerRef}
                    style={{ width: "90%", height: "500px", backgroundColor: "yellow", position: "relative", overflowX: "auto", overflowY: "auto" }}>

                </div>
            </div>
            <div style={{ width: "20%" }}>
                <button onClick={addSignature}>Add Signature</button>
            </div>
        </div>
    );
};

export default Test;
