import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateDocument.css';
import ConvertApi from 'convertapi-js';

const CreateDocument = () => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [pdfLink, setPdfLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();
  const convertApi = ConvertApi.auth('secret_2G1uRQ3NyPB1NIB6');

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    
    const allowedTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint
      'image/jpeg',
      'image/png',
    ];

    if (uploadedFile) {
      if (allowedTypes.includes(uploadedFile.type)) {
        setFile(uploadedFile);
        setErrorMessage('');
        setPdfLink(null);

        if (uploadedFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
          await handleConvertToPDF(uploadedFile, 'pptx');
        } else if (uploadedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          await handleConvertToWord(uploadedFile, 'docx');
        }

      } else {
        setFile(null);
        setErrorMessage(
          'Unsupported file type. Please upload a PDF, Word, PowerPoint, JPG, or PNG.'
        );
      }
    }
  };

  const handleConvertToPDF = async (uploadedFile, type) => {
    if (!uploadedFile) {
      setError('Please select a valid file first.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const params = convertApi.createParams();
      params.add('File', uploadedFile);

      const result = await convertApi.convert(type, 'pdf', params);

      if (result && result.dto && result.dto.Files && result.dto.Files.length > 0) {
        const pdfFileUrl = result.dto.Files[0].Url;
        setPdfLink(pdfFileUrl);
        setSuccessMessage('Conversion Successful! PDF is ready.');
      } else {
        setError('Conversion failed or result is missing the file URL.');
      }
    } catch (err) {
      setError(`Conversion failed: ${err.message}`);
      console.error('Error during conversion:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleConvertToWord = async (uploadedFile, type) => {
    if (!uploadedFile) {
      setError('Please select a valid file first.');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const params = convertApi.createParams();
      params.add('File', uploadedFile);

      // Conversion API call for DOCX to PDF
      const result = await convertApi.convert(type, 'pdf', params);

      if (result && result.dto && result.dto.Files && result.dto.Files.length > 0) {
        const pdfFileUrl = result.dto.Files[0].Url;
        setPdfLink(pdfFileUrl);
        setSuccessMessage('Conversion Successful! PDF is ready.');
      } else {
        setError('Conversion failed or result is missing the file URL.');
      }
    } catch (err) {
      setError(`Conversion failed: ${err.message}`);
      console.error('Error during conversion:', err);
    } finally {
      setLoading(false);
    }
  };


  const handleContinue = () => {
    if (file) {
      navigate('/add-recipients', { state: { file, pdfLink } });
    }
  };

  return (
    <div className="create-document-container">
      <h2>Create a Document</h2>
      <div className="upload-box">
        <label className="file-upload-label">
          <input
            type="file"
            onChange={handleFileUpload}
            className="file-input"
          />
          <span>Select files</span>
        </label>
        <p className="file-info">
          Supported files: PDF, Word, PowerPoint, JPG, PNG
        </p>
        {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
        {loading && <p className="loading-message">Loading...</p>}
        {file && <p className="loading-message">{file.name}</p>}
      </div>

      <button
        className="continue-button"
        onClick={handleContinue}
        disabled={!file || loading}
      >
        Next
      </button>

    </div>
  );
};

export default CreateDocument;
