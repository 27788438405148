import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const DownloadDocument = () => {
    const location = useLocation();
    const { adminEmailPdfUrl } = location.state || {};
    console.log("adminEmailPdfUrl",adminEmailPdfUrl)
    const [pages, setPages] = useState([]);
        
    useEffect(() => {
        if (adminEmailPdfUrl) {
            const renderPDF = async () => {
                try {
                    const pdf = await getDocument(adminEmailPdfUrl).promise;
                    const pageCanvases = [];
                    
                    for (let i = 1; i <= pdf.numPages; i++) {
                        const page = await pdf.getPage(i);
                        const viewport = page.getViewport({ scale: 1.5 });

                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        await page.render(renderContext).promise;
                        pageCanvases.push(canvas.toDataURL());
                    }

                    setPages(pageCanvases);
                } catch (error) {
                    console.error('Error rendering PDF:', error);
                }
            };

            renderPDF();
        }
    }, [adminEmailPdfUrl]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    borderBottom: '1px solid #EFEDEC',
                    padding: '10px',
                    boxSizing: 'border-box',
                }}
            >
                <button
                    onClick={() => {
                        if (adminEmailPdfUrl) {
                            window.open(adminEmailPdfUrl, '_blank');
                        } else {
                            alert('PDF URL not available');
                        }
                    }}
                >
                    Download Document
                </button>
            </div>
            <div
                style={{
                    width: '100%',
                    height: '95vh',
                    backgroundColor: '#EFEDEC',
                    overflowY: 'auto',
                    overflowX: "none",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    scrollbarWidth: "none", 
                }}
            >
                {adminEmailPdfUrl ? (
                    pages.map((page, index) => (
                        <img
                            key={index}
                            src={page}
                            alt={`Page ${index + 1}`}
                            style={{
                                width: '75%',
                                marginBottom: '20px',
                                border: '1px solid white',
                            }}
                        />
                    ))
                ) : (
                    <div style={{ color: 'white' }}>PDF not available</div>
                )}
            </div>
        </div>
    );
};

export default DownloadDocument;
