import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleCreateDocument = () => {
    navigate('/create-document');
  };

  return (
    <div className="dashboard-container">
      <h2>Welcome to Document Manager</h2>
      <button onClick={handleCreateDocument}>+ Create Document</button>
      <Link to="/document-list">
        <button >Document List</button>
      </Link>
      <Link to="/final-document">
        <button >Ready Document List</button>
      </Link>
    </div>
  );
};

export default Dashboard;
