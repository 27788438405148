import React from 'react';

const Successfully = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: "#f0f0f0", background: "#EFEDEC" }}>
            <div style={{
                backgroundColor: "white",
                padding: "40px",
                borderRadius: "10px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                maxWidth: "500px",
                textAlign: "center"
            }}>
                <h1 style={{ color: "#4CAF50" }}>Success!</h1>
                <p style={{ fontSize: "18px", margin: "20px 0" }}>
                    Congratulations! You have successfully signed the document. You can now proceed with the next steps in your process.
                </p>
            </div>
        </div>
    );
}

export default Successfully;
