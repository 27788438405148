import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Modal from './Modal';
import './SendDocument.css';

const SendDocument = () => {
  const location = useLocation();
  const { recipients = [], documentName, emails, uniqueToken, } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  const handleEmailMessageChange = (e) => {
    setEmailMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailData = {
      emails,
      subject: "IgnitedVapes",
      emailMessage,
      boxStyles: "background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 8px; padding: 20px; font-family: Arial, sans-serif; color: #333;",
      documentName,
      uniqueToken,
      recipients
    };

    try {
      setLoading(true);
      const response = await fetch("https://node-project-theta.vercel.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      const result = await response.json();
      setLoading(false);
      if (response.ok) {
        setModalShow(true);
      } else {
        alert("Error: " + result.error);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error sending email:", error);
      alert("An error occurred while sending the email.");
    }
  };

  return (
    <>
      {
        loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="send-document-container">
              <h2>Send Document</h2>
              <div className="form-group">
                <label>To:</label>
                <div className="recipient-list">
                  {recipients.map((recipient, index) => (
                    <div key={index} className="recipient-email">
                      {recipient.email}
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-group">
                <label>Email message</label>
                <textarea
                  placeholder="Optional message..."
                  value={emailMessage}
                  onChange={handleEmailMessageChange}
                  className="input-field"
                />
              </div>
              <button onClick={handleSubmit} className="send-document-button">
                Send Document
              </button>
            </div>
            {
              modalShow && (
                <Modal isOpen={modalShow} >
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h1 style={{ color: "#2ecc71" }}>Success</h1>
                    <h1>Email Successfully Send</h1>
                    <Link to="/">
                      <button style={{ backgroundColor: "#2ecc71", padding: "15px 40px", color: "white" }}>Ok</button>
                    </Link>
                  </div>
                </Modal>
              )
            }
          </>
        )
      }
    </>
  );
};

export default SendDocument;
