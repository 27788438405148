import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './App.css';
import Dashboard from './components/Dashboard';
import CreateDocument from './components/CreateDocument';
import AddRecipients from './components/AddRecipients';
import DocumentPreview from './components/DocumentPreview';
import ReviewRecipients from './components/ReviewRecipients';
import SendDocument from './components/SendDocument';
import Test from './components/Test';
import Successfully from './components/Successfully';
import DocumentList from './components/DocumentList';
import FinalDocument from './components/FinalDocument';
import DownloadDocument from './components/DownloadDocument';
function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/create-document" element={<CreateDocument />} />
          <Route path="/add-recipients" element={<AddRecipients />} />
          <Route path="/sign" element={<DocumentPreview />} />
          <Route path="/review-recipients" element={<ReviewRecipients />} />  {/* New Route */}
          <Route path="/send-document" element={<SendDocument />} />  {/* New Route */}
          <Route path="/success" element={<Successfully />} />
          <Route path="/test" element={<Test />} />
          <Route path="/document-list" element={<DocumentList />} />
          <Route path="/final-document" element={<FinalDocument />} />
          <Route path="/download-document" element={<DownloadDocument />} />
          <Route path="*" element={<h2>Page not found</h2>} />
        </Routes>
      </Router>
    </DndProvider>
  );
}

export default App;
