import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import mammoth from 'mammoth';
import { useDrop } from 'react-dnd';
import './DocumentPreview.css';
import PdfViewer from './PdfViewer';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@mui/material';
import AWS from 'aws-sdk';
import { createRoot } from 'react-dom/client';
import { PDFDocument, rgb } from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';
import * as fontkit from 'fontkit';

const ItemTypes = {
  SIGNATURE: 'signature',
};

const DocumentPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  const { file, pdfLink, recipients = [], adminEmailPdfUrl, adminDocumentName, jsonFileToken } = location.state || {};
  const [signatures, setSignatures] = useState([]);
  const [isSignatureRendered, setIsSignatureRendered] = useState(false);
  const [signatureFile, setSignatureFile] = useState(null);
  const [canvasBox, setCanvasBox] = useState();
  const [slideHeights, setSlideHeights] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const viewerRef = useRef();
  const [activeRecipientEmail, setActiveRecipientEmail] = useState("");
  const [selectedRecipient, setSelectedRecipient] = useState(activeRecipientEmail || recipients[0]?.email || '');
  const [selectedRecipientName, setSelectedRecipientName] = useState(recipients[0]?.name || '');
  const [recipientList, setRecipientList] = useState(
    recipients.map((recipient) => ({
      ...recipient,
      color: recipient.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    }))
  );
  const [isEditingSignature, setIsEditingSignature] = useState(false);
  const [activeSignature, setActiveSignature] = useState(null);
  const [optionsSignature, setOptionsSignature] = useState(null);
  const [signatureType, setSignatureType] = useState('');
  const signatureElementRef = useRef(null);
  const [typedSignature, setTypedSignature] = useState('');
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const [font, setFont] = useState('FF Market');
  const [color, setColor] = useState('#000000');
  const docxContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const [getFileType, setGetFileType] = useState(file?.type || "");
  const [showRecipientModal, setShowRecipientModal] = useState(false);
  const [newRecipient, setNewRecipient] = useState({ name: '', email: '', phone: '' });
  const [fileUrl, setFileUrl] = useState(null);
  const [docxContent, setDocxContent] = useState(null);
  const redBoxRef = useRef(null);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState('');
  const [progress, setProgress] = useState(0);
  const [pdfLinkOfPPTX, setPdfLinkOfPPTX] = useState(null);
  const [queryPdfUrl, setQueryPdfUrl] = useState(null);
  const [activeUser, setActiveUser] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [finishDocument, setFinishDocument] = useState(false);
  const [uniqueToken, setUniqueToken] = useState("");
  const [token, setToken] = useState("");
  const [isValidSignature, setIsValidSignature] = useState(false);
  const [downloadDocument, setDownloadDocument] = useState(false);
  const [showFinishDocument, setShowFinishDocument] = useState(false);
  const [adminCheck, setAdminCheck] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [pdfLoad, setPdfLoad] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  useEffect(() => {
    const fetchParamsFromHash = () => {
      const hash = window.location.hash;
      const queryString = hash.includes("?") ? hash.split("?")[1] : "";
      const queryParams = new URLSearchParams(queryString);

      const token = queryParams.get("token");
      const activeEmail = queryParams.get("email");
      const activeUserName = queryParams.get("name");
      const documentName = queryParams.get("documentName");
      setToken(token);
      setDocumentName(documentName);
      setActiveUser(activeUserName);
      setActiveRecipientEmail(activeEmail);

      fetch(`https://myreact29bucket.s3.eu-north-1.amazonaws.com/User+Data/${token}.json`)
        .then((response) => response.json())
        .then((data) => {
          setQueryPdfUrl(data.emailPdfUrl);
          setSignatures([...data.signatures]);
          setFinishDocument(data.emailPdfUrl ? true : false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchParamsFromHash();
  }, []);

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setFileUrl(url);

      if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const convertDocxToHtml = async () => {
          try {
            const arrayBuffer = await file.arrayBuffer();
            const { value } = await mammoth.convertToHtml({ arrayBuffer });
            setDocxContent(value);
          } catch (error) {
            console.error("Error converting .docx file:", error);
          }
        };
        convertDocxToHtml();
      }

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  // State to manage button disabled state

  useEffect(() => {
    // Function to check if all recipients have at least one signature
    const checkSignaturesForRecipients = () => {
      // Get a list of all unique recipient emails
      const uniqueRecipients = [...new Set(recipients.map(r => r.email))];

      // Check if every recipient has at least one signature
      const allRecipientsHaveSignatures = uniqueRecipients.every((recipientEmail) =>
        signatures.some(signature => signature.recipientEmail === recipientEmail)
      );

      // Update the button state based on the result
      setDisabledButton(!allRecipientsHaveSignatures);
    };

    // Recalculate whenever signatures or recipients change
    checkSignaturesForRecipients();
  }, [signatures, recipients]);



  const handleCheckboxChange = (event) => {
    setAdminCheck(event.target.checked);
  };

  const checkValidSignatures = () => {
    const filteredSignatures = signatures.filter(signature => signature.recipientEmail === activeRecipientEmail);
    setShowFinishDocument(filteredSignatures.length > 0);
    const allSignaturesEdited = filteredSignatures.every(signature => signature.signatureIsEdit === true);
    setIsValidSignature(allSignaturesEdited);
  };

  const checkSignatureIsPresent = async () => {
    const jsonUrl = `https://myreact29bucket.s3.eu-north-1.amazonaws.com/User+Data/${token}.json`;

    try {
      const response = await fetch(jsonUrl);
      if (response.ok) {
        const data = await response.json();
        if (data.signatures && data.signatures.length > 0) {
          setDownloadDocument(false);
        } else {
          setDownloadDocument(true);
        }
      } else {
        console.error('Failed to fetch the JSON file');
      }
    } catch (error) {
      console.error('Error fetching or processing JSON:', error);
    }
  };

  useEffect(() => {
    checkSignatureIsPresent();
  }, [activeRecipientEmail]);

  useEffect(() => {
    checkValidSignatures();
  }, [signatures]);

  const addSignature = () => {
    const slides = viewerRef.current.querySelectorAll(
      ".pdf-slide, .docx-slide, .image-slide"
    );

    if (slides.length === 0) {
      alert("No slides available to add a signature!");
      return;
    }

    const currentSlide = slides[currentSlideIndex];
    const slideWidth = currentSlide.offsetWidth;
    const slideHeight = currentSlide.offsetHeight;

    const containerScrollTop = viewerRef.current.scrollTop;
    const containerHeight = viewerRef.current.offsetHeight;
    const slideTopInContainer = currentSlide.offsetTop;

    const visibleSlideTop = Math.max(slideTopInContainer, containerScrollTop);
    const visibleSlideBottom = Math.min(
      slideTopInContainer + slideHeight,
      containerScrollTop + containerHeight
    );
    const visibleHeight = visibleSlideBottom - visibleSlideTop;

    if (visibleHeight <= 0) {
      alert("The current slide is not visible in the viewport!");
      return;
    }

    const marginTop = 20;
    const marginBottom = 70;
    const visibleTopInSlide = visibleSlideTop - slideTopInContainer + marginTop;
    const visibleBottomInSlide =
      visibleSlideTop - slideTopInContainer + visibleHeight - marginBottom;

    let randomLeft;

    if (window.innerWidth > 1300) {
      randomLeft = Math.random() * (slideWidth - 186);
      if (randomLeft > 820) {
        randomLeft = 800;
      }
    } else {
      randomLeft = Math.random() * (slideWidth - 186);
    }

    const randomTop =
      visibleTopInSlide +
      Math.random() * (visibleBottomInSlide - visibleTopInSlide - 64);

    const signature = {
      id: crypto.randomUUID(),
      left: randomLeft / slideWidth,
      top: randomTop / slideHeight,
      slideIndex: currentSlideIndex,
      signatureText: "Signature",
      recipientsName: selectedRecipientName,
      recipientEmail: selectedRecipient,
      signatureContentFixed: false,
      signatureIsEdit: false,
    };

    setSignatures((prevSignatures) => [...prevSignatures, signature]);

    const createSignatureElement = (left, top, signatureData = signature) => {
      const signatureElement = document.createElement("div");
      signatureElement.setAttribute("data-id", signatureData.id);
      const signatureClass = "signature-element";
      signatureElement.classList.add(signatureClass);
      signatureElement.style.position = "absolute";

      if (window.innerWidth <= 500) {
        signatureElement.style.width = "22vw";
        signatureElement.style.height = "8vw";
        signatureElement.style.fontSize = "0.6rem";
      } else if (window.innerWidth <= 800) {
        signatureElement.style.width = "20vw";
        signatureElement.style.height = "6vw";
        signatureElement.style.fontSize = "1rem";
      } else if (window.innerWidth <= 1280) {
        signatureElement.style.width = "14vw";
        signatureElement.style.height = "5.5vw";
        signatureElement.style.fontSize = "1.3rem";
      } else if (window.innerWidth > 1280) {
        signatureElement.style.width = "14vw";
        signatureElement.style.height = "5.5vw";
        signatureElement.style.fontSize = "1.5rem";
      }

      const recipientColor = recipientList.find(
        (r) => r.email === selectedRecipient
      )?.color;

      const darkColors = [
        "#0B0C10",
        "#1C1C1C",
        "#2C3E50",
        "#2B2B2B",
        "#3D3D3D",
        "#4B0082",
        "#483D8B",
        "#2F4F4F",
        "#1A1A1D",
        "#212121",
        "#343434",
        "#000000",
        "#4A4A4A"
      ];

      signatureElement.style.border = `2px solid ${recipientColor}`;

      signatureElement.style.backgroundColor = convertHexToRgba(
        recipientColor || darkColors[Math.floor(Math.random() * darkColors.length)],
        0.5
      );

      signatureElement.style.color = "white";
      signatureElement.style.textAlign = "center";
      signatureElement.style.lineHeight = "50px";
      signatureElement.style.top = `${top}px`;
      signatureElement.style.left = `${left}px`;
      signatureElement.style.borderRadius = "5px";
      signatureElement.style.zIndex = 10;
      signatureElement.style.cursor = "pointer";
      signatureElement.style.display = "flex";
      signatureElement.style.justifyContent = "center";
      signatureElement.style.alignItems = "center";
      signatureElement.style.userSelect = "none";
      signatureElement.style.maxWidth = "calc(100% - 20px)";

      signatureElementRef.current = signatureElement;

      function convertHexToRgba(hex, opacity) {
        if (!hex) return "rgba(0, 0, 0, 0)";
        hex = hex.replace("#", "");
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }

      if (React.isValidElement(signatureData.signature)) {
        const root = ReactDOM.createRoot(signatureElement);
        root.render(signatureData.signature);
      } else {
        signatureElement.innerText = signatureData.signatureText || "Signature";
      }

      const popup = document.createElement("div");
      popup.setAttribute("popup", signatureData.id);
      popup.style.position = "absolute";
      popup.style.display = "none";
      popup.style.gap = "0.8vw";
      popup.style.flexDirection = "row";
      popup.style.backgroundColor = "black";
      popup.style.opacity = "0.6";
      popup.style.border = "1px solid gray";
      popup.style.boxShadow = "0 4px 8px rgba(0,0,0,0.2)";
      popup.style.zIndex = 11;
      popup.style.padding = "1vw 0.5vw ";

      if (window.innerWidth <= 500) {
        popup.style.top = `${top}px`;
      } else {
        popup.style.top = `${top - 50}px`;
      }
      popup.style.left = `${left}px`;

      const createPopupButton = (text, callback) => {
        const button = document.createElement("div");
        button.style.flex = "1";
        button.style.textAlign = "center";
        button.style.cursor = "pointer";
        button.style.color = "white";
        button.style.fontSize = window.innerWidth <= 500 ? "10px" : "13px";
        button.style.userSelect = "none";
        button.innerText = text;
        button.addEventListener("click", callback);
        return button;
      };

      const closeButton = createPopupButton("X", (e) => {
        e.stopPropagation();
        popup.style.display = "none";
      });

      const copyButton = createPopupButton("Copy", (e) => {
        e.stopPropagation();

        setSignatures((prevSignatures) => {
          const clickedSignature = prevSignatures.find((sig) => sig.id === signatureData.id);

          if (!clickedSignature) return prevSignatures;

          const updatedSlideIndex = clickedSignature.slideIndex;

          const slideWidth = currentSlide.offsetWidth;
          const slideHeight = currentSlide.offsetHeight;

          const visibleSlideTop = Math.max(slideTopInContainer, containerScrollTop);
          const visibleSlideBottom = Math.min(
            slideTopInContainer + slideHeight,
            containerScrollTop + containerHeight
          );

          const visibleTopInSlide = visibleSlideTop - slideTopInContainer + marginTop;
          const visibleBottomInSlide =
            visibleSlideTop - slideTopInContainer + (visibleSlideBottom - visibleSlideTop) - marginBottom;

          const newRandomLeft = Math.random() * (slideWidth - 186);
          const newRandomTop =
            visibleTopInSlide +
            Math.random() * (visibleBottomInSlide - visibleTopInSlide - 74);

          const newSignature = {
            ...clickedSignature,
            id: crypto.randomUUID(),
            left: newRandomLeft / slideWidth,
            top: newRandomTop / slideHeight,
          };

          const updatedSignatures = [...prevSignatures, newSignature];

          createSignatureElement(newRandomLeft, newRandomTop, newSignature);

          return updatedSignatures;
        });
      });

      const deleteButton = createPopupButton("Delete", (e) => {
        e.stopPropagation();
        setSignatures((prevSignatures) =>
          prevSignatures.filter((sig) => sig.id !== signatureData.id)
        );
        currentSlide.removeChild(signatureElement);
        currentSlide.removeChild(popup);
      });


      popup.appendChild(closeButton);
      popup.appendChild(copyButton);
      popup.appendChild(deleteButton);

      if (adminEmailPdfUrl) {
        const editButton = createPopupButton("Edit", (e) => {
          e.stopPropagation();
          setIsEditingSignature(true);
          setActiveSignature(signatureData);
          setTypedSignature(
            recipientList.find((r) => r.email === selectedRecipient)?.name || ""
          );
        });
        popup.appendChild(editButton);
      }

      const handleInteraction = (e) => {
        e.stopPropagation();
        popup.style.display = popup.style.display === "none" ? "flex" : "none";
      };

      signatureElement.addEventListener("click", handleInteraction);
      signatureElement.addEventListener("touchstart", handleInteraction);

      document.addEventListener("click", () => {
        popup.style.display = "none";
      });

      let offsetX, offsetY, isDragging = false;

      let draggedElement = null;
      let currentTargetSlide = null;
      let targetSlideIndex = null;

      let draggedSignatureId = null;

      signatureElement.addEventListener("pointerdown", (e) => {
        isDragging = true;
        offsetX = e.clientX - signatureElement.offsetLeft;
        offsetY = e.clientY - signatureElement.offsetTop;
        signatureElement.style.opacity = "0.8";
        draggedElement = signatureElement;

        draggedSignatureId = signatureData.id;

        const enforceBounds = (left, top, slide) => {
          const verticalPadding = 5;

          const maxLeft = slide.offsetWidth - signatureElement.offsetWidth;
          const maxTop = slide.offsetHeight - signatureElement.offsetHeight - verticalPadding;

          return {
            left: Math.min(Math.max(0, left), maxLeft),
            top: Math.min(Math.max(verticalPadding, top), maxTop),
          };
        };

        let throttleTimeout = null;

        const onPointerMove = (e) => {
          const clientX = e.touches ? e.touches[0].clientX : e.clientX;
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;

          if (isDragging && draggedSignatureId === signatureData.id) {
            const newLeft = clientX - offsetX;
            const newTop = clientY - offsetY;

            signatureElement.style.left = `${newLeft}px`;
            signatureElement.style.top = `${newTop}px`;

            updatePopupPosition(newLeft, newTop);

            const targetSlide = currentTargetSlide || slides[signature.slideIndex];
            const { left: boundedLeft, top: boundedTop } = enforceBounds(
              newLeft,
              newTop,
              targetSlide
            );

            if (!throttleTimeout) {
              throttleTimeout = setTimeout(() => {
                setSignatures((prevSignatures) =>
                  prevSignatures.map((sig) =>
                    sig.id === draggedSignatureId
                      ? {
                        ...sig,
                        left: boundedLeft / targetSlide.offsetWidth,
                        top: boundedTop / targetSlide.offsetHeight,
                      }
                      : sig
                  )
                );
                throttleTimeout = null;
              }, 100);
            }

            let detectedSlideIndex = -1;
            slides.forEach((slide, index) => {
              const rect = slide.getBoundingClientRect();
              if (
                clientX > rect.left &&
                clientX < rect.right &&
                clientY > rect.top &&
                clientY < rect.bottom
              ) {
                detectedSlideIndex = index;
              }
            });

            if (detectedSlideIndex !== -1) {
              currentTargetSlide = slides[detectedSlideIndex];
              targetSlideIndex = detectedSlideIndex;
            }
          }
        };

        const onPointerUp = (e) => {
          isDragging = false;

          if (currentTargetSlide && targetSlideIndex !== signature.slideIndex) {
            const slideRect = currentTargetSlide.getBoundingClientRect();
            const clientX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
            const clientY = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

            const newLeft = clientX - slideRect.left;
            const newTop = clientY - slideRect.top;

            const { left: boundedLeft, top: boundedTop } = enforceBounds(
              newLeft,
              newTop,
              currentTargetSlide
            );

            signatureElement.style.left = `${boundedLeft}px`;
            signatureElement.style.top = `${boundedTop}px`;

            updatePopupPosition(boundedLeft, boundedTop);

            currentTargetSlide.appendChild(signatureElement);
            currentTargetSlide.appendChild(popup);

            setSignatures((prevSignatures) =>
              prevSignatures.map((sig) =>
                sig.id === draggedSignatureId // Update the dragged signature only
                  ? { ...sig, left: boundedLeft, top: boundedTop, slideIndex: targetSlideIndex }
                  : sig
              )
            );

            signature.slideIndex = targetSlideIndex;
          } else {
            const currentSlide = slides[signature.slideIndex];
            const { left: boundedLeft, top: boundedTop } = enforceBounds(
              signatureElement.offsetLeft,
              signatureElement.offsetTop,
              currentSlide
            );

            signatureElement.style.left = `${boundedLeft}px`;
            signatureElement.style.top = `${boundedTop}px`;

            updatePopupPosition(boundedLeft, boundedTop);
          }

          document.removeEventListener("pointermove", onPointerMove);
          document.removeEventListener("pointerup", onPointerUp);
          document.removeEventListener("touchmove", onPointerMove);
          document.removeEventListener("touchend", onPointerUp);
        };

        document.addEventListener("pointermove", onPointerMove);
        document.addEventListener("pointerup", onPointerUp);
        document.addEventListener("touchmove", onPointerMove);
        document.addEventListener("touchend", onPointerUp);
      });

      signatureElement.addEventListener("touchstart", (e) => {
        e.preventDefault();
        isDragging = true;
        offsetX = e.touches[0].clientX - signatureElement.offsetLeft;
        offsetY = e.touches[0].clientY - signatureElement.offsetTop;
        signatureElement.style.opacity = "0.8";
      });

      const updatePopupPosition = (newLeft, newTop) => {
        const signatureHeight = signatureElement.offsetHeight;
        const popupHeight = 50;
        const margin = 10;

        if (window.innerWidth <= 500) {
          if (newTop <= popupHeight + margin) {
            popup.style.top = `${newTop + signatureHeight + 10}px`;
          } else {
            popup.style.top = `${newTop - popupHeight + 20}px`;
          }
          popup.style.left = `${newLeft}px`;
        } else {
          if (newTop <= popupHeight + margin) {
            popup.style.top = `${newTop + signatureHeight + 10}px`;
          } else {
            popup.style.top = `${newTop - popupHeight - margin}px`;
          }
          popup.style.left = `${newLeft}px`;
        };
      }

      currentSlide.appendChild(signatureElement);
      currentSlide.appendChild(popup);

      return signatureElement;
    };


    currentSlide.appendChild(createSignatureElement(randomLeft, randomTop));
  };

  useEffect(() => {
    const handleZoomOrResize = () => {
      const slides = viewerRef.current.querySelectorAll(".pdf-slide, .image-slide");

      slides.forEach((slide, index) => {
        const slideWidth = slide.offsetWidth;
        const slideHeight = slide.offsetHeight;

        setSignatures((prevSignatures) =>
          prevSignatures.map((signature) => {
            if (signature.slideIndex === index) {
              const newLeft = signature.left * slideWidth;
              const newTop = signature.top * slideHeight;

              const signatureElement = slide.querySelector(`[data-id="${signature.id}"]`);
              if (signatureElement) {
                signatureElement.style.left = `${newLeft}px`;
                signatureElement.style.top = `${newTop}px`;
              }

              const popupElement = slide.querySelector(`[popup="${signature.id}"]`);
              if (popupElement) {
                updatePopupPosition(popupElement, newLeft, newTop, signatureElement.offsetHeight);
              }
            }
            return signature;
          })
        );
      });
    };

    const updatePopupPosition = (popup, newLeft, newTop, signatureHeight) => {
      const popupHeight = 50;
      const margin = 10;

      if (newTop <= popupHeight + margin) {
        popup.style.top = `${newTop + signatureHeight + margin}px`;
      } else {
        popup.style.top = `${newTop - popupHeight - margin}px`;
      }

      popup.style.left = `${newLeft}px`;
    };

    window.addEventListener("resize", handleZoomOrResize);
    window.addEventListener("zoom", handleZoomOrResize);

    return () => {
      window.removeEventListener("resize", handleZoomOrResize);
      window.removeEventListener("zoom", handleZoomOrResize);
    };
  }, [viewerRef, signatures]);

  const showSignatureElement = (signature, slides, currentSlide, setSignatures, setIsEditingSignature, setActiveSignature, setTypedSignature, recipientList, selectedRecipient, activeRecipientEmail) => {
    const { slideIndex, left, top, signatureText, id, recipientEmail, signatureIsEdit } = signature;
    const isControllable = recipientEmail === activeRecipientEmail;
    const signatureDiv = document.createElement("div");
    signatureDiv.setAttribute("data-id", id);
    signatureDiv.style.position = "absolute";

    if (window.innerWidth <= 500) {
      signatureDiv.style.width = "22vw";
      signatureDiv.style.height = "8vw";
      signatureDiv.style.fontSize = "0.6rem";
    } else if (window.innerWidth <= 800) {
      signatureDiv.style.width = "20vw";
      signatureDiv.style.height = "6vw";
      signatureDiv.style.fontSize = "1rem";
    } else if (window.innerWidth <= 1280) {
      signatureDiv.style.width = "14vw";
      signatureDiv.style.height = "5.5vw";
      signatureDiv.style.fontSize = "1.3rem";
    } else if (window.innerWidth > 1280) {
      signatureDiv.style.width = "14vw";
      signatureDiv.style.height = "5.5vw";
      signatureDiv.style.fontSize = "1.5rem";
    }
    signatureDiv.style.backgroundColor = isControllable ? convertHexToRgba("#00FF00", 0.2) : convertHexToRgba("#99a3a4", 0.1);
    signatureDiv.style.color = "white";
    signatureDiv.style.border = `2px solid ${convertHexToRgba(isControllable ? "#00FF00" : "#99a3a4", 0.5)}`;
    signatureDiv.style.display = "flex";
    signatureDiv.style.justifyContent = "center";
    signatureDiv.style.alignItems = "center";
    signatureDiv.style.borderRadius = "5px";
    signatureDiv.style.cursor = isControllable ? "pointer" : "not-allowed";
    signatureDiv.style.userSelect = "none";
    signatureDiv.innerText = signatureText;
    signatureDiv.style.left = `${left * 100}%`;
    signatureDiv.style.top = `${top * 100}%`;

    signatureElementRef.current = signatureDiv;

    function convertHexToRgba(hex, opacity) {
      if (!hex) return "rgba(0, 0, 0, 0)";
      hex = hex.replace("#", "");
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    if (React.isValidElement(signature.signature)) {
      const root = ReactDOM.createRoot(signatureDiv);
      root.render(signature.signature);
    } else {
      signatureDiv.innerText = signature.signatureText || "Signature";
    }

    const createPopup = () => {
      const popupDiv = document.createElement("div");
      popupDiv.style.position = "absolute";
      popupDiv.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      popupDiv.style.color = "white";
      popupDiv.style.padding = "1.2vw 1vw";
      popupDiv.style.borderRadius = "5px";
      popupDiv.style.textAlign = "center";
      popupDiv.style.zIndex = "1000";
      popupDiv.style.fontSize = window.innerWidth <= 500 ? "10px" : "13px";

      const signatureRect = signatureDiv.getBoundingClientRect();
      const slideRect = slides[slideIndex].getBoundingClientRect();

      const signatureTop = signatureRect.top - slideRect.top;
      const signatureLeft = signatureRect.left - slideRect.left;

      const popupHeight = 50;
      const margin = 10;

      if (window.innerWidth <= 500) {
        if (signatureTop <= popupHeight + margin) {
          popupDiv.style.top = `${signatureTop + signatureRect.height + 10}px`;
        } else {
          popupDiv.style.top = `${signatureTop - popupHeight + 20}px`;
        }
        popupDiv.style.left = `${signatureLeft}px`;
      } else {
        if (signatureTop <= popupHeight + margin) {
          popupDiv.style.top = `${signatureTop + signatureRect.height + 10}px`;
        } else {
          popupDiv.style.top = `${signatureTop - popupHeight - margin}px`;
        }
        popupDiv.style.left = `${signatureLeft}px`;
      }

      const editText = document.createElement("span");
      editText.innerText = "Sign";
      editText.style.marginRight = "1vw";
      editText.style.cursor = "pointer";
      editText.onclick = () => {
        setIsEditingSignature(true);
        setActiveSignature(signature);
        setTypedSignature(
          recipientList.find((r) => r.email === selectedRecipient)?.name || ""
        );
        popupDiv.style.display = "none";
      };

      const closeText = document.createElement("span");
      closeText.innerText = "X";
      closeText.style.cursor = "pointer";
      closeText.onclick = () => {
        popupDiv.style.display = "none";
      };

      popupDiv.appendChild(editText);
      popupDiv.appendChild(closeText);

      return popupDiv;
    };

    let popup = null;

    if (isControllable) {
      signatureDiv.addEventListener("click", (e) => {
        e.stopPropagation();

        if (popup && popup.style.display !== "none") {
          popup.style.display = "none";
        } else {
          popup = createPopup();
          slides[slideIndex].appendChild(popup);
        }
      });
    }

    return { signatureDiv, popup };
  };

  const renderSignature = () => {
    if (isSignatureRendered) return;

    const slides = viewerRef.current.querySelectorAll(".pdf-slide, .docx-slide, .image-slide");
    signatures.forEach((signature) => {
      const { slideIndex } = signature;
      const targetSlide = slides[slideIndex];
      if (!targetSlide) return;


      const { signatureDiv, popup } = showSignatureElement(
        signature,
        slides,
        slides[currentSlideIndex],
        setSignatures,
        setIsEditingSignature,
        setActiveSignature,
        setTypedSignature,
        recipientList,
        selectedRecipient,
        activeRecipientEmail
      );

      targetSlide.appendChild(signatureDiv);
      if (popup) {
        targetSlide.appendChild(popup);
      }

    });

    setIsSignatureRendered(true);
  };

  const fontUrls = {
    "FF Market": "/font/FFMarket/Market.ttf",
    "MadreScript": "https://db.onlinewebfonts.com/t/078dccb5f3be0956233a6975ccbf4975.ttf",
    "Dancing Script": "https://db.onlinewebfonts.com/t/be7d00cc3e81bca7bd01f0924f5d5b73.ttf",
    "Great Vibes": "https://db.onlinewebfonts.com/t/5bf06596a053153248631d74f9fc4e28.ttf",
    "Pacifico": "https://db.onlinewebfonts.com/t/6b6170fe52fb23f505b4e056fefd2679.ttf",
    "Satisfy": "https://db.onlinewebfonts.com/t/4b6d03ce5461faeda7d8e785d1a2351a.ttf"
  };

  const fetchFontBytes = async (fontUrl) => {
    const response = await fetch(fontUrl);
    if (!response.ok) {
      throw new Error(`Failed to load font from ${fontUrl}`);
    }
    return await response.arrayBuffer();
  };

  const handleSubmit = async () => {
    const emailData = {
      email: "gorillahashcompany@gmail.com",
      subject: "Document is signed",
      boxStyles: "background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 8px; padding: 20px; font-family: Arial, sans-serif; color: #333;",
      documentName: documentName,
      uniqueToken: token,
    };

    try {
      const response = await fetch("https://node-project-theta.vercel.app/send-admin-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      const result = await response.json();
      if (response.ok) {
        console.log("Success", result);
      } else {
        console.error("Error")
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleFinishDocument = async () => {
    try {
      setLoading(true);
      setProgress(0);
      const s3JsonUrl = `https://myreact29bucket.s3.eu-north-1.amazonaws.com/User+Data/${token}.json`;
      let metadata = {};
      const activeEmail = activeRecipientEmail;
      const slides = viewerRef.current.querySelectorAll(
        ".pdf-slide, .docx-slide, .image-slide"
      );
      try {
        const response = await fetch(s3JsonUrl);
        if (response.ok) {
          metadata = await response.json();
        } else {
          console.warn("Metadata JSON not found. Creating a new one.");
        }
      } catch (error) {
        console.error("Error fetching existing metadata:", error);
      }

      const pdfUrl = queryPdfUrl;
      const existingPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      pdfDoc.registerFontkit(fontkit);

      setProgress(10);

      const totalSignatures = signatures.length;

      for (let i = 0; i < signatures.length; i++) {
        const signature = signatures[i];
        if (signature.signatureIsEdit) {
          try {
            const { slideIndex, left, top, signature: signatureValue } = signature;
            const page = pdfDoc.getPage(slideIndex);
            const slides = viewerRef.current.querySelectorAll(
              ".pdf-slide, .docx-slide, .image-slide"
            );
            const slideElement = slides[slideIndex];
            if (!slideElement) {
              throw new Error("Slide not found for the given index.");
            }

            const { width: pdfWidth, height: pdfHeight } = page.getSize();

            // Get the dimensions of the corresponding slide element
            const slideWidth = slideElement.offsetWidth;
            const slideHeight = slideElement.offsetHeight;

            // Calculate scaling factors
            const scaleX = pdfWidth / slideWidth;
            const scaleY = pdfHeight / slideHeight;

            // Box dimensions in pixels
            let boxWidthPx;
            let boxHeightPx;

            if (window.innerWidth <= 500) {
              boxWidthPx = (30 / 100) * window.innerWidth;
              boxHeightPx = (10 / 100) * window.innerWidth;
            } else if (window.innerWidth <= 800) {
              boxWidthPx = (20 / 100) * window.innerWidth;
              boxHeightPx = (6 / 100) * window.innerWidth;
            } else if (window.innerWidth <= 1280) {
              boxWidthPx = (14 / 100) * window.innerWidth;
              boxHeightPx = (5.5 / 100) * window.innerWidth;
            } else {
              boxWidthPx = (14 / 100) * window.innerWidth;
              boxHeightPx = (5.5 / 100) * window.innerWidth;
            }

            const boxX = left * slideWidth * scaleX;
            const boxY = pdfHeight - (top * slideHeight * scaleY) - boxHeightPx * scaleY;

            if (React.isValidElement(signatureValue) && signatureValue.type === "span") {
              const text = signatureValue.props.children || "";
              const style = signatureValue.props.style || {};
              const color = style.color || "#000000";
              const font = parseFloat(style.fontSize) || 13;
              const fontFamily = style.fontFamily || "MadreScript";

              const fontUrl = fontUrls[fontFamily] || fontUrls["MadreScript"];
              const customFontBytes = await fetchFontBytes(fontUrl);
              const customFont = await pdfDoc.embedFont(customFontBytes);

              const hexToRgb = (hex) => {
                const bigint = parseInt(hex.slice(1), 16);
                return {
                  r: ((bigint >> 16) & 255) / 255,
                  g: ((bigint >> 8) & 255) / 255,
                  b: (bigint & 255) / 255,
                };
              };

              const { r, g, b } = hexToRgb(color);

              const textSize = font;
              const textWidth = customFont.widthOfTextAtSize(text, textSize) * scaleX;
              const textHeight = customFont.heightAtSize(textSize) * scaleY;

              const textX = boxX + (boxWidthPx * scaleX - textWidth) / 2;
              const textY = boxY + (boxHeightPx * scaleY - textHeight) / 2;

              page.drawText(text, {
                x: textX,
                y: textY,
                size: textSize * scaleX,
                font: customFont,
                color: rgb(r, g, b),
              });
            } else if (React.isValidElement(signatureValue) && signatureValue.type === "img") {
              const imageUrl = signatureValue.props.src;
              if (!imageUrl) {
                throw new Error("Image URL is missing.");
              }

              let embedImageFunction;
              if (imageUrl.includes("data:image/jpeg") || imageUrl.includes("data:image/jpg")) {
                embedImageFunction = pdfDoc.embedJpg.bind(pdfDoc);
              } else if (imageUrl.includes("data:image/png")) {
                embedImageFunction = pdfDoc.embedPng.bind(pdfDoc);
              } else {
                throw new Error("Unsupported image format");
              }

              const base64Data = imageUrl.split(",")[1];
              const imageBytes = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
              const embeddedImage = await embedImageFunction(imageBytes);

              const scaleFactor = Math.min(
                (boxWidthPx * scaleX) / embeddedImage.width,
                (boxHeightPx * scaleY) / embeddedImage.height
              );

              const imageWidth = embeddedImage.width * scaleFactor;
              const imageHeight = embeddedImage.height * scaleFactor;

              page.drawImage(embeddedImage, {
                x: boxX + (boxWidthPx * scaleX - imageWidth) / 2,
                y: boxY + (boxHeightPx * scaleY - imageHeight) / 2,
                width: imageWidth,
                height: imageHeight,
              });
            }

            signatures.splice(i, 1);
            i--;

          } catch (embedError) {
            console.error("Error embedding signature:", embedError);
          }
        }

        const progressPercentage = ((i + 1) / signatures.length) * 70 + 10;
        setProgress(progressPercentage);
      }

      const modifiedPdfBytes = await pdfDoc.save();
      setProgress(80);

      const uniqueToken = uuidv4();
      const s3PdfKey = `${uniqueToken}.pdf`;
      const s3PdfUrl = `https://myreact29bucket.s3.eu-north-1.amazonaws.com/User+Data/${token}.json/${s3PdfKey}`;

      await fetch(s3PdfUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/pdf",
        },
        body: modifiedPdfBytes,
      });

      if (metadata.signatures) {
        metadata.signatures = metadata.signatures.filter(
          (signature) => signature.recipientEmail !== activeEmail
        );
      }

      if (metadata.adminCheck !== "success") {
        if (metadata.signatures && metadata.signatures.length === 0) {
          metadata.adminCheck = "true";
        }
      } else {
        metadata.adminCheck = "success";
      }


      metadata.emailPdfUrl = s3PdfUrl;
      const updatedMetadata = JSON.stringify(metadata);

      await fetch(s3JsonUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: updatedMetadata,
      });

      if (metadata.adminCheck === "true" && (!metadata.signatures || metadata.signatures.length === 0)) {
        const adminDetailUrl = `https://myreact29bucket.s3.eu-north-1.amazonaws.com/Admin/AdminDetail.json`;
        let adminDetails = [];

        try {
          const adminResponse = await fetch(adminDetailUrl);
          if (adminResponse.ok) {
            adminDetails = await adminResponse.json();
          } else {
            console.warn("AdminDetail.json not found. Creating a new one.");
          }
        } catch (adminError) {
          console.warn("Error fetching AdminDetail.json. Assuming it doesn't exist.");
        }

        const newAdminEntry = {
          token,
          documentName: metadata.documentName || "Unnamed Document",
          emailPdfUrl: metadata.emailPdfUrl,
          adminCheck: metadata.adminCheck,
        };

        adminDetails.push(newAdminEntry);

        // Upload AdminDetail.json to the folder
        await fetch(adminDetailUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(adminDetails),
        });
      } else {
        console.error("Error")
      }

      handleSubmit();
      setProgress(100);
      setLoading(false);
      navigate("/success")
    } catch (error) {
      console.error("Error finalizing the document:", error);
      setLoading(false);
      setProgress(0);
      alert("An error occurred while finalizing the document.");
    }
  };

  useEffect(() => {
    let timeout;

    if (viewerRef.current) {
      if (activeRecipientEmail && pdfLoad) {
        setLoading(true);
        renderSignature();
        setLoading(false);
      } else if (activeRecipientEmail && !pdfLoad) {
        setLoading(true);
        timeout = setTimeout(() => {
          if (pdfLoad) {
            renderSignature();
            setLoading(false);
          }
        }, 1000);
      }
    }

    return () => {
      clearTimeout(timeout);
      setLoading(false);
      if (!pdfLoad) setPdfLoading(false);
    };
  }, [signatures, viewerRef, pdfLoad]);


  const exportToPDF = async (file, pdfLinkOfPPTX, signatures) => {
    try {
      const s3 = new AWS.S3({
        accessKeyId: "AKIAQWHCQIPZSLCHUV6G",
        secretAccessKey: "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",
        region: "us-east-1",
      });

      setLoading(true);
      setProgress(0);

      let pdfDoc;
      let pdfBytes;

      if (file && file.type.startsWith("image/")) {
        const arrayBuffer = await file.arrayBuffer();
        pdfDoc = await PDFDocument.create();

        let embeddedImage;
        if (file.type === "image/jpeg" || file.type === "image/jpg") {
          embeddedImage = await pdfDoc.embedJpg(arrayBuffer);
        } else if (file.type === "image/png") {
          embeddedImage = await pdfDoc.embedPng(arrayBuffer);
        } else {
          throw new Error("Unsupported image format. Only JPG and PNG are supported.");
        }
        const page = pdfDoc.addPage();
        const { width, height } = embeddedImage;
        page.setSize(width, height);
        page.drawImage(embeddedImage, {
          x: 0,
          y: 0,
          width: width,
          height: height,
        });
      } else if (pdfLinkOfPPTX) {
        const response = await fetch(pdfLinkOfPPTX);
        if (!response.ok) {
          throw new Error("Failed to fetch PDF content from the provided URL.");
        }
        pdfBytes = await response.arrayBuffer();
        pdfDoc = await PDFDocument.load(pdfBytes);
      } else if (file) {
        pdfBytes = await file.arrayBuffer();
        pdfDoc = await PDFDocument.load(pdfBytes);
      } else {
        throw new Error("No valid input provided for PDF generation.");
      }

      pdfDoc.registerFontkit(fontkit);

      const totalSignatures = signatures.length;

      for (let i = 0; i < signatures.length; i++) {
        const signature = signatures[i];
        if (signature.signatureIsEdit) {
          try {
            const { slideIndex, left, top, signature: signatureValue } = signature;
            const page = pdfDoc.getPage(slideIndex);
            const slides = viewerRef.current.querySelectorAll(
              ".pdf-slide, .docx-slide, .image-slide"
            );
            const slideElement = slides[slideIndex];
            if (!slideElement) {
              throw new Error("Slide not found for the given index.");
            }

            const { width: pdfWidth, height: pdfHeight } = page.getSize();

            const slideWidth = slideElement.offsetWidth;
            const slideHeight = slideElement.offsetHeight;

            const scaleX = pdfWidth / slideWidth;
            const scaleY = pdfHeight / slideHeight;

            let boxWidthPx;
            let boxHeightPx;

            if (window.innerWidth <= 500) {
              boxWidthPx = (30 / 100) * window.innerWidth;
              boxHeightPx = (10 / 100) * window.innerWidth;
            } else if (window.innerWidth <= 800) {
              boxWidthPx = (20 / 100) * window.innerWidth;
              boxHeightPx = (6 / 100) * window.innerWidth;
            } else if (window.innerWidth <= 1280) {
              boxWidthPx = (14 / 100) * window.innerWidth;
              boxHeightPx = (5.5 / 100) * window.innerWidth;
            } else {
              boxWidthPx = (14 / 100) * window.innerWidth;
              boxHeightPx = (5.5 / 100) * window.innerWidth;
            }

            const boxX = left * slideWidth * scaleX;
            const boxY = pdfHeight - (top * slideHeight * scaleY) - boxHeightPx * scaleY;

            if (React.isValidElement(signatureValue) && signatureValue.type === "span") {
              const text = signatureValue.props.children || "";
              const style = signatureValue.props.style || {};
              const color = style.color || "#000000";
              const font = parseFloat(style.fontSize) || 13;
              const fontFamily = style.fontFamily || "MadreScript";

              const fontUrl = fontUrls[fontFamily] || fontUrls["MadreScript"];
              const customFontBytes = await fetchFontBytes(fontUrl);
              const customFont = await pdfDoc.embedFont(customFontBytes);

              const hexToRgb = (hex) => {
                const bigint = parseInt(hex.slice(1), 16);
                return {
                  r: ((bigint >> 16) & 255) / 255,
                  g: ((bigint >> 8) & 255) / 255,
                  b: (bigint & 255) / 255,
                };
              };

              const { r, g, b } = hexToRgb(color);

              const textSize = font;
              const textWidth = customFont.widthOfTextAtSize(text, textSize) * scaleX;
              const textHeight = customFont.heightAtSize(textSize) * scaleY;

              const textX = boxX + (boxWidthPx * scaleX - textWidth) / 2;
              const textY = boxY + (boxHeightPx * scaleY - textHeight) / 2;

              page.drawText(text, {
                x: textX,
                y: textY,
                size: textSize * scaleX,
                font: customFont,
                color: rgb(r, g, b),
              });
            } else if (React.isValidElement(signatureValue) && signatureValue.type === "img") {
              const imageUrl = signatureValue.props.src;
              if (!imageUrl) {
                throw new Error("Image URL is missing.");
              }

              let embedImageFunction;
              if (imageUrl.includes("data:image/jpeg") || imageUrl.includes("data:image/jpg")) {
                embedImageFunction = pdfDoc.embedJpg.bind(pdfDoc);
              } else if (imageUrl.includes("data:image/png")) {
                embedImageFunction = pdfDoc.embedPng.bind(pdfDoc);
              } else {
                throw new Error("Unsupported image format");
              }

              const base64Data = imageUrl.split(",")[1];
              const imageBytes = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
              const embeddedImage = await embedImageFunction(imageBytes);

              const scaleFactor = Math.min(
                (boxWidthPx * scaleX) / embeddedImage.width,
                (boxHeightPx * scaleY) / embeddedImage.height
              );

              const imageWidth = embeddedImage.width * scaleFactor;
              const imageHeight = embeddedImage.height * scaleFactor;

              page.drawImage(embeddedImage, {
                x: boxX + (boxWidthPx * scaleX - imageWidth) / 2,
                y: boxY + (boxHeightPx * scaleY - imageHeight) / 2,
                width: imageWidth,
                height: imageHeight,
              });
            }

            signatures.splice(i, 1);
            i--;

          } catch (embedError) {
            console.error("Error embedding signature:", embedError);
          }
        }

        const progressPercentage = ((i + 1) / signatures.length) * 70 + 10;
        setProgress(progressPercentage);
      }


      const modifiedPdfBytes = await pdfDoc.save();

      const params = {
        Bucket: "myreact29bucket",
        Key: `uploaded-files/${Date.now()}.pdf`,
        Body: modifiedPdfBytes,
        ContentType: "application/pdf",
        ACL: "public-read",
      };

      const uploadResult = await s3.upload(params).on("httpUploadProgress", (progressData) => {
        const percentage = Math.round((progressData.loaded / progressData.total) * 100);
        setProgress(percentage);
      }).promise();

      const emailPdfUrl = uploadResult.Location;

      const uniqueToken = `token-${Date.now()}`;
      const userData = {
        token: uniqueToken,
        emailPdfUrl: emailPdfUrl,
        signatures: signatures,
        adminCheck: adminCheck ? "false" : "success",
        documentName: file?.name || documentName,
      };

      const userDataParams = {
        Bucket: "myreact29bucket",
        Key: `User Data/${uniqueToken}.json`,
        Body: JSON.stringify(userData),
        ContentType: "application/json",
        ACL: "public-read",
      };

      await s3.upload(userDataParams).promise();

      setUniqueToken(uniqueToken);
      setLoading(false);
    } catch (error) {
      console.error("Error exporting file:", error);
      setLoading(false);
      alert("Failed to upload file.");
    }
  };

  const exportAdminPDF = async (adminEmailPdfUrl, signatures, jsonFileToken) => {
    if (!signatures || (Array.isArray(signatures) && signatures.length === 0)) {
      alert("Please add a Signature");
      return;
    }

    try {
      const s3 = new AWS.S3({
        accessKeyId: "AKIAQWHCQIPZSLCHUV6G",
        secretAccessKey: "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",
        region: "us-east-1",
      });

      setLoading(true);
      setProgress(0);

      const response = await fetch(adminEmailPdfUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch PDF content from the provided URL.");
      }
      const pdfBytes = await response.arrayBuffer();
      let pdfDoc = await PDFDocument.load(pdfBytes);

      pdfDoc.registerFontkit(fontkit);

      for (let i = 0; i < signatures.length; i++) {
        const signature = signatures[i];
        if (signature.signatureIsEdit) {
          try {
            const { slideIndex, left, top, signature: signatureValue } = signature;
            const page = pdfDoc.getPage(slideIndex);

            const slides = viewerRef.current.querySelectorAll(".pdf-slide, .docx-slide, .image-slide");
            const slideElement = slides[slideIndex];
            if (!slideElement) {
              throw new Error("Slide not found for the given index.");
            }

            const { width: pdfWidth, height: pdfHeight } = page.getSize();
            const slideWidth = slideElement.offsetWidth;
            const slideHeight = slideElement.offsetHeight;

            const scaleX = pdfWidth / slideWidth;
            const scaleY = pdfHeight / slideHeight;

            let boxWidthPx, boxHeightPx;

            if (window.innerWidth <= 500) {
              boxWidthPx = (30 / 100) * window.innerWidth;
              boxHeightPx = (10 / 100) * window.innerWidth;
            } else if (window.innerWidth <= 800) {
              boxWidthPx = (20 / 100) * window.innerWidth;
              boxHeightPx = (6 / 100) * window.innerWidth;
            } else if (window.innerWidth <= 1280) {
              boxWidthPx = (14 / 100) * window.innerWidth;
              boxHeightPx = (5.5 / 100) * window.innerWidth;
            } else {
              boxWidthPx = (14 / 100) * window.innerWidth;
              boxHeightPx = (5.5 / 100) * window.innerWidth;
            }

            const boxX = left * slideWidth * scaleX;
            const boxY = pdfHeight - (top * slideHeight * scaleY) - boxHeightPx * scaleY;

            if (React.isValidElement(signatureValue) && signatureValue.type === "span") {
              const text = signatureValue.props.children || "";
              const style = signatureValue.props.style || {};
              const color = style.color || "#000000";
              const fontSize = parseFloat(style.fontSize) || 13;
              const fontFamily = style.fontFamily || "MadreScript";

              const fontUrl = fontUrls[fontFamily] || fontUrls["MadreScript"];
              const customFontBytes = await fetchFontBytes(fontUrl);
              const customFont = await pdfDoc.embedFont(customFontBytes);

              const hexToRgb = (hex) => {
                const bigint = parseInt(hex.slice(1), 16);
                return {
                  r: ((bigint >> 16) & 255) / 255,
                  g: ((bigint >> 8) & 255) / 255,
                  b: (bigint & 255) / 255,
                };
              };

              const { r, g, b } = hexToRgb(color);
              const textWidth = customFont.widthOfTextAtSize(text, fontSize) * scaleX;
              const textHeight = customFont.heightAtSize(fontSize) * scaleY;

              const textX = boxX + (boxWidthPx * scaleX - textWidth) / 2;
              const textY = boxY + (boxHeightPx * scaleY - textHeight) / 2;

              page.drawText(text, {
                x: textX,
                y: textY,
                size: fontSize * scaleX,
                font: customFont,
                color: rgb(r, g, b),
              });
            } else if (React.isValidElement(signatureValue) && signatureValue.type === "img") {
              const imageUrl = signatureValue.props.src;
              if (!imageUrl) {
                throw new Error("Image URL is missing.");
              }

              let embedImageFunction;
              if (imageUrl.includes("data:image/jpeg") || imageUrl.includes("data:image/jpg")) {
                embedImageFunction = pdfDoc.embedJpg.bind(pdfDoc);
              } else if (imageUrl.includes("data:image/png")) {
                embedImageFunction = pdfDoc.embedPng.bind(pdfDoc);
              } else {
                throw new Error("Unsupported image format");
              }

              const base64Data = imageUrl.split(",")[1];
              const imageBytes = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));
              const embeddedImage = await embedImageFunction(imageBytes);

              const scaleFactor = Math.min(
                (boxWidthPx * scaleX) / embeddedImage.width,
                (boxHeightPx * scaleY) / embeddedImage.height
              );

              const imageWidth = embeddedImage.width * scaleFactor;
              const imageHeight = embeddedImage.height * scaleFactor;

              page.drawImage(embeddedImage, {
                x: boxX + (boxWidthPx * scaleX - imageWidth) / 2,
                y: boxY + (boxHeightPx * scaleY - imageHeight) / 2,
                width: imageWidth,
                height: imageHeight,
              });
            }

            signatures.splice(i, 1);
            i--;
          } catch (embedError) {
            console.error("Error embedding signature:", embedError);
          }
        }

        const modifiedPdfBytes = await pdfDoc.save();

        const params = {
          Bucket: "myreact29bucket",
          Key: `uploaded-files/${Date.now()}.pdf`,
          Body: modifiedPdfBytes,
          ContentType: "application/pdf",
          ACL: "public-read",
        };

        const uploadResult = await s3.upload(params).on("httpUploadProgress", (progressData) => {
          const percentage = Math.round((progressData.loaded / progressData.total) * 100);
          setProgress(percentage);
        }).promise();

        const emailPdfUrl = uploadResult.Location;

        await updateUserDataFile(
          "https://myreact29bucket.s3.eu-north-1.amazonaws.com",
          jsonFileToken,
          emailPdfUrl
        );

        // Update Admin Details
        await updateAdminDetails(
          "https://myreact29bucket.s3.eu-north-1.amazonaws.com/Admin/AdminDetail.json",
          jsonFileToken,
          emailPdfUrl
        );

        setLoading(false);
        navigate("/final-document");
      }
    } catch (error) {
      console.error("Error exporting file:", error);
      setLoading(false);
      alert("Failed to upload file.");
    }
  };

  const updateAdminDetails = async (adminDetailsUrl, jsonFileToken, emailPdfUrl) => {
    try {
      const response = await fetch(adminDetailsUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch Admin Detail JSON.");
      }
      const adminDetails = await response.json();

      const matchingEntry = adminDetails.find((entry) => entry.token === jsonFileToken);
      if (!matchingEntry) {
        console.error("Token not found in Admin Detail JSON.");
        return;
      }

      matchingEntry.adminCheck = "success";
      matchingEntry.emailPdfUrl = emailPdfUrl;

      const updatedAdminDetails = JSON.stringify(adminDetails, null, 2);

      const s3 = new AWS.S3({
        accessKeyId: "AKIAQWHCQIPZSLCHUV6G",
        secretAccessKey: "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",
        region: "eu-north-1",
      });

      const params = {
        Bucket: "myreact29bucket",
        Key: "Admin/AdminDetail.json",
        Body: updatedAdminDetails,
        ContentType: "application/json",
        ACL: "public-read",
      };

      await s3.upload(params).promise();
    } catch (error) {
      console.error("Error updating Admin Detail JSON:", error);
    }
  };

  const updateUserDataFile = async (bucketUrl, jsonFileToken, emailPdfUrl) => {
    try {
      const response = await fetch(`${bucketUrl}/User+Data/${jsonFileToken}.json`);
      if (!response.ok) {
        throw new Error("Failed to fetch user data JSON file.");
      }

      const jsonData = await response.json();

      jsonData.emailPdfUrl = emailPdfUrl;

      const updatedJsonString = JSON.stringify(jsonData);

      const s3 = new AWS.S3({
        accessKeyId: "AKIAQWHCQIPZSLCHUV6G",
        secretAccessKey: "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",
        region: "us-east-1",
      });

      const params = {
        Bucket: "myreact29bucket",
        Key: `User Data/${jsonFileToken}.json`,
        Body: updatedJsonString,
        ContentType: "application/json",
        ACL: "public-read",
      };

      const uploadResult = await s3.upload(params).promise();
    } catch (error) {
      console.error("Error updating user data JSON file:", error);
      throw new Error("Failed to update user data file.");
    }
  };


  useEffect(() => {
    if (uniqueToken) {
      setTimeout(() => {
        navigate('/review-recipients', { state: { recipients: recipientList, documentName: file?.name, file, emails: selectedRecipient, uniqueToken } });
      }, 3000);
    }
  }, [uniqueToken]);

  const downloadSignedDocument = async () => {
    const jsonUrl = `https://myreact29bucket.s3.eu-north-1.amazonaws.com/User+Data/${token}.json`;

    try {
      const response = await fetch(jsonUrl);
      if (response.ok) {
        const data = await response.json();

        const emailPdfUrl = data.emailPdfUrl;

        if (emailPdfUrl) {
          const link = document.createElement('a');
          link.href = emailPdfUrl;
          link.download = emailPdfUrl.split('/').pop();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('emailPdfUrl is not available in the JSON data.');
        }
      } else {
        console.error('Failed to fetch the JSON file');
      }
    } catch (error) {
      console.error('Error fetching or processing JSON:', error);
    }
  };

  const handleSlideHeights = (heights) => {
    setSlideHeights(heights);
  };

  const handleSaveSignature = async () => {

    if (!activeSignature) {
      console.error("[Error] No active signature to save.");
      return;
    }

    const signatureContent = generateSignatureContent();
    if (!signatureContent) {
      alert("Please Fill the Signature");
      return;
    }

    AWS.config.update({
      accessKeyId: "AKIAQWHCQIPZSLCHUV6G",
      secretAccessKey: "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",
      region: "us-east-1",
    });

    const s3 = new AWS.S3();

    const updatedSignatures = signatures.map((sig) =>
      sig.id === activeSignature.id
        ? {
          ...sig,
          signatureText: typedSignature || activeUser || "Signature",
          signature: signatureContent,
          position: sig.position,
          signatureIsEdit: true
        }
        : sig
    );

    setSignatures(updatedSignatures);

    const slides = viewerRef.current.querySelectorAll(
      ".pdf-slide, .docx-slide, .image-slide"
    );

    if (!slides[activeSignature.slideIndex]) {
      console.error("[Error] Slide not found for the active signature:", activeSignature.slideIndex);
      return;
    }

    const currentSlide = slides[activeSignature.slideIndex];

    const signatureElement = currentSlide.querySelector(
      `div[data-id="${activeSignature.id}"]`
    );

    if (!signatureElement) {
      console.error("[Error] Signature element not found:", activeSignature.id);
      return;
    }

    try {
      const updatedSignature = updatedSignatures.find((sig) => sig.id === activeSignature.id);

      if (!updatedSignature || !updatedSignature.signature) {
        console.error("[Error] Invalid signature:", updatedSignature.signature);
        return;
      }

      const isImage = React.isValidElement(updatedSignature.signature) && updatedSignature.signature.type === "img";

      const newContent = isImage
        ? updatedSignature.signature
        : updatedSignature.signature;


      if (window.innerWidth < 500) {
        signatureElement.style.fontSize = typedSignature.length > 10 ? "6px" : "12px";
      } else if (window.innerWidth > 1280) {
        signatureElement.style.fontSize = typedSignature.length > 30 ? "10px" : "18px";
      } else {
        signatureElement.style.fontSize = typedSignature.length > 30 ? "5px" : "15px";
      }


      let root = signatureElement._reactRootContainer;

      if (!root) {
        root = createRoot(signatureElement);
      }

      root.render(newContent);

      const token = `${token}`;
      const s3Key = `User Data/${token}.json`;
      const s3Data = JSON.stringify(updatedSignatures);

      const params = {
        Bucket: "myreact29bucket",
        Key: s3Key,
        Body: s3Data,
        ContentType: "application/json",
      };

      s3.putObject(params, (err, data) => {
        if (err) {
          console.error("[Error] Failed to save signature to S3:", err);
        } else {
          console.log("[Success] Signature saved to S3:", data);
        }
      });

      setSignatureType("");
      setIsEditingSignature(false);
    } catch (error) {
      console.error("[Error] Failed to render signature:", error);
    }

    setIsEditingSignature(false);
    setActiveSignature(null);
    setTypedSignature("");
    setUploadedSignature(null);
    setSignatureType("");
  };

  const generateSignatureContent = () => {
    const recipientName =
      recipientList.find((r) => r.email === selectedRecipient)?.name ||
      activeUser ||
      "Signature";

    let textSize;

    if (window.innerWidth > 1280) {
      textSize = typedSignature.length > 30 ? "10" : "18";
    } else {
      textSize = typedSignature.length > 30 ? "5" : "15";
    }

    const style = {
      fontFamily: font,
      color,
      maxWidth: "100%",
      maxHeight: "100%",
      fontSize: textSize,
    };

    if (signatureType === "typed") {
      if (typedSignature || recipientName) {
        return <span style={style}>{typedSignature || recipientName}</span>;
      } else {
        console.error("[Error] No typed signature provided.");
        return null;
      }
    } else if (signatureType === "upload") {
      if (uploadedSignature) {
        return <img src={uploadedSignature} alt="Uploaded Signature" style={style} />;
      } else {
        console.error("[Error] No uploaded signature provided.");
        return null;
      }
    } else if (signatureType === "draw") {
      if (canvasRef.current) {
        const drawnImage = canvasRef.current.toDataURL();

        const blankCanvas = document.createElement("canvas");
        blankCanvas.width = canvasRef.current.width;
        blankCanvas.height = canvasRef.current.height;
        const blankImage = blankCanvas.toDataURL();

        if (drawnImage === blankImage) {
          console.error("[Error] The canvas is empty. Please draw your signature.");
          return null;
        }
        return <img src={drawnImage} alt="Drawn Signature" style={style} />;
      } else {
        console.error("[Error] No drawn signature available.");
        return null;
      }
    }

    console.error("[Error] Unsupported signature type or missing signature data.");
    return null;
  };

  const handleSignatureUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        setErrorMessage('No File Support');
        return;
      }
      setErrorMessage('');

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result;
        setUploadedSignature(base64Image);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];

    if (file) {
      const fileType = file.type.split("/")[0];

      if (fileType === "image") {
        const reader = new FileReader();
        reader.onloadend = async () => {
          setUploadedSignature(reader.result);
        };

        reader.readAsDataURL(file);

        setErrorMessage("");

      } else {
        setErrorMessage("File Not Supported");
      }
    } else {
      setErrorMessage("No file dropped");
    }
  };

  const setupDrawingCanvas = useCallback(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      let isDrawing = false;

      const getCoordinates = (e) => {
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        if (e.touches) {
          return {
            x: (e.touches[0].clientX - rect.left) * scaleX,
            y: (e.touches[0].clientY - rect.top) * scaleY,
          };
        }
        return {
          x: (e.clientX - rect.left) * scaleX,
          y: (e.clientY - rect.top) * scaleY,
        };
      };

      const startDrawing = (e) => {
        e.preventDefault();
        const { x, y } = getCoordinates(e);
        ctx.beginPath();
        ctx.moveTo(x, y);
        isDrawing = true;
      };

      const stopDrawing = () => {
        isDrawing = false;
        ctx.closePath();
      };

      const draw = (e) => {
        if (!isDrawing) return;
        const { x, y } = getCoordinates(e);
        ctx.lineTo(x, y);
        ctx.stroke();
      };

      canvas.addEventListener("mousedown", startDrawing);
      canvas.addEventListener("mouseup", stopDrawing);
      canvas.addEventListener("mousemove", draw);

      canvas.addEventListener("touchstart", startDrawing);
      canvas.addEventListener("touchend", stopDrawing);
      canvas.addEventListener("touchmove", draw);

      document.addEventListener("mouseup", stopDrawing);
      document.addEventListener("touchend", stopDrawing);

      return () => {
        canvas.removeEventListener("mousedown", startDrawing);
        canvas.removeEventListener("mouseup", stopDrawing);
        canvas.removeEventListener("mousemove", draw);
        canvas.removeEventListener("touchstart", startDrawing);
        canvas.removeEventListener("touchend", stopDrawing);
        canvas.removeEventListener("touchmove", draw);
        document.removeEventListener("mouseup", stopDrawing);
        document.removeEventListener("touchend", stopDrawing);
      };
    }
  }, [color]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (signatureType === 'draw') {
      setupDrawingCanvas();
    }
  }, [signatureType, setupDrawingCanvas]);

  const [{ }, drop] = useDrop({
    accept: ItemTypes.SIGNATURE,
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const index = item.index;
      const updatedSignatures = [...signatures];

      const container = docxContainerRef.current;
      if (!container) return;

      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      const signatureWidth = 210;
      const signatureHeight = 45;

      let newX = updatedSignatures[index].position.x + delta.x;
      let newY = updatedSignatures[index].position.y + delta.y;

      if (newX < 0) {
        newX = 0;
      } else if (newX + signatureWidth > containerWidth) {
        newX = containerWidth - signatureWidth;
      }

      if (newY < 0) {
        newY = 0;
      } else if (newY + signatureHeight > containerHeight) {
        newY = containerHeight - signatureHeight;
      }

      updatedSignatures[index] = {
        ...updatedSignatures[index],
        position: { x: newX, y: newY },
      };

      setSignatures(updatedSignatures);
      setOptionsSignature(null);
    },
  });

  const handleAddRecipient = () => setShowRecipientModal(true);

  const handleSaveRecipient = () => {
    const newColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    setRecipientList([...recipientList, { ...newRecipient, color: newColor }]);
    setSelectedRecipient(newRecipient.email);
    setNewRecipient({ name: '', email: '', phone: '' });
    setShowRecipientModal(false);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;

    if (email === "gorillahashcompany@gmail.com") {
      setErrorMessage("This email is not allowed.");
      setDisabledButton(true);
    } else {
      setErrorMessage("");
      setDisabledButton(false);
    }

    setNewRecipient({ ...newRecipient, email });
  };

  const renderRecipientModal = () => (
    <div className="recipient-modal">
      <div className="recipient-modal-content">
        <h3>Add Recipient</h3>
        <input
          type="text"
          placeholder="Enter recipient name"
          value={newRecipient.name}
          onChange={(e) => setNewRecipient({ ...newRecipient, name: e.target.value })}
          className="input-field"
        />
        <input
          type="email"
          placeholder="Enter recipient email"
          value={newRecipient.email}
          onChange={handleEmailChange}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Enter phone number"
          value={newRecipient.phone}
          onChange={(e) => setNewRecipient({ ...newRecipient, phone: e.target.value })}
          className="input-field"
        />
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <button disabled={disabledButton} onClick={handleSaveRecipient} className="save-button">Save</button>
        <button onClick={() => { setShowRecipientModal(false), setErrorMessage(""), setNewRecipient("") }} className="cancel-button">Cancel</button>
      </div>
    </div>
  );

  const renderSignaturePopup = () => (
    <div className="signature-popup">
      <div className="signature-popup-content">
        <h3>Edit Signature for {recipientList.find((r) => r.email === selectedRecipient)?.name}</h3>
        <div className="signature-options">
          <button onClick={() => setSignatureType('typed')}>Type</button>
          <button onClick={() => setSignatureType('draw')}>Draw</button>
          <button onClick={() => setSignatureType('upload')}>Upload</button>
        </div>

        {signatureType === 'typed' && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <p style={{ marginBottom: '8px' }}>Choose font:</p>
                <select
                  value={font}
                  onChange={(e) => setFont(e.target.value)}
                  className="font-select"
                  style={{
                    width: '150px',
                    padding: '8px',
                    fontFamily: font,
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <option value="MadreScript" style={{ fontFamily: 'MadreScript' }}>{recipientList.find((r) => r.email === selectedRecipient)?.name || activeUser || "Admin"}</option>
                  <option value="Dancing Script" style={{ fontFamily: 'Dancing Script' }}>{recipientList.find((r) => r.email === selectedRecipient)?.name || activeUser || "Admin"}</option>
                  <option value="Great Vibes" style={{ fontFamily: 'Great Vibes' }}>{recipientList.find((r) => r.email === selectedRecipient)?.name || activeUser || "Admin"}</option>
                  <option value="Pacifico" style={{ fontFamily: 'Pacifico' }}>{recipientList.find((r) => r.email === selectedRecipient)?.name || activeUser || "Admin"}</option>
                  <option value="Satisfy" style={{ fontFamily: 'Satisfy' }}>{recipientList.find((r) => r.email === selectedRecipient)?.name || activeUser || "Admin"}</option>
                </select>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <p style={{ marginBottom: '8px' }}>Choose color:</p>
                <div style={{ display: 'flex', gap: '8px' }}>
                  {['#000000', '#FF0000', '#0000FF', '#00FF00'].map((colorOption) => (
                    <div
                      key={colorOption}
                      onClick={() => setColor(colorOption)}
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: colorOption,
                        cursor: 'pointer',
                        border: color === colorOption ? '2px solid #555' : '2px solid transparent',
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <input
              type="text"
              placeholder="Type your signature"
              value={typedSignature || recipientList.find((r) => r.email === selectedRecipient)?.name || activeUser || "Admin"}
              onChange={(e) => setTypedSignature(e.target.value)}
              className="input-field"
              style={{
                padding: '8px',
                width: '100%',
                maxWidth: '300px',
                fontSize: '16px',
                fontFamily: font,
                color: color,
                marginTop: '8px',
                border: 'none',
                borderBottom: '2px solid black',
                outline: 'none',
                boxSizing: 'border-box',
                borderRadius: "0px"
              }}
            />
          </div>
        )}

        {signatureType === 'draw' && (
          <div style={{ display: "flex", flexDirection: "column", gap: "20px", margin: "10px auto", width: "80%" }}>
            <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
              <label>Drawing Color: </label>
              <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
            </div>
            <canvas
              ref={canvasRef}
              className="signature-canvas"
              width="300"
              height="100"
              style={{ border: '1px solid black', cursor: 'crosshair' }}
            />
          </div>
        )}

        {signatureType === 'upload' && (
          <div
            className="upload-section"
            style={{
              border: '1px dashed #ccc',
              padding: '10px',
              textAlign: 'center',
              position: 'relative',
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <p>Drag & drop a file here or</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <button className="choose-file-button" onClick={() => document.getElementById('uploadInput').click()}>
                Choose a Local File
              </button>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </div>
            <input
              id="uploadInput"
              type="file"
              onChange={handleSignatureUpload}
              style={{ display: 'none' }}
            />
            {uploadedSignature && (
              <div style={{ marginTop: '20px' }}>
                <img
                  src={uploadedSignature}
                  alt="Preview"
                  style={{ height: 'auto', maxHeight: "16vw", width: "auto", maxWidth: "100%", backgroundColor: "green" }}
                />
              </div>
            )}
          </div>
        )}

        <button onClick={handleSaveSignature} className="save-button">Save</button>
        <button className="close-popup-button" onClick={() => {
          setIsEditingSignature(false);
          setErrorMessage(false);
          setUploadedSignature(false);
        }} >Close</button>
      </div>
    </div>
  );


  return (
    <>
      <div className={`document-preview-container ${loading ? "loading" : ""}`}>
        {loading && (
          <div className="loading-overlay">
            <CircularProgress />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: "7vh"
          }}
        >
          <h2 style={{ margin: '0 10px', textAlign: 'center', flexGrow: 1, fontSize: "13px", }}>
            Preview Document: {file?.name || documentName || adminDocumentName}
          </h2>
          <div className='menu-button'>
            <h2 style={{ marginRight: '10px', cursor: 'pointer' }} onClick={toggleMenu}>
              Menu
            </h2>
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            top: '0',
            right: isMenuOpen ? '0' : '-270px',
            width: '250px',
            height: '100%',
            backgroundColor: 'lightgray',
            transition: 'right 0.3s ease',
            padding: '10px 10px',
            boxSizing: "border-box",
            zIndex: '1000',
          }}
        >
          <div onClick={toggleMenu} style={{ display: "flex", justifyContent: "flex-start", fontWeight: "bold" }}>X</div>
          <h3>Recipients</h3>
          <select
            value={selectedRecipient}
            onChange={(e) => setSelectedRecipient(e.target.value)}
            className="recipient-select"
            style={{
              color: recipientList.find((r) => r.email === selectedRecipient)?.color || "inherit",
            }}
          >
            {recipientList && recipientList.length > 0
              ? recipientList.map((recipient, index) => (
                <option
                  key={index}
                  value={recipient.email}
                  style={{ color: recipient.color }}
                >
                  {`${recipient.name} (${recipient.email})`}
                </option>
              ))
              : (
                <option value={activeRecipientEmail}>
                  {activeRecipientEmail || "No recipients available"}
                </option>
              )}
          </select>
          {downloadDocument && (
            <button className="add-recipient-button" onClick={downloadSignedDocument}>Download Document</button>
          )}
          {finishDocument ? (
            <></>
          ) : (
            <>
              {adminEmailPdfUrl ? (<></>) : (
                <button className="add-recipient-button" onClick={handleAddRecipient}>Add Recipient</button>
              )}
              <button
                className="add-signature-button"
                onClick={() => {
                  addSignature();
                }}
              >
                Add Signature
              </button>
              {
                adminEmailPdfUrl ? (
                  <button
                    className="add-signature-button"
                    onClick={() => exportAdminPDF(adminEmailPdfUrl, signatures, jsonFileToken)}
                    style={{ marginTop: '10px' }}
                    disabled={signatures.length === 0}
                  >
                    Send
                  </button>
                ) : (
                  <button
                    className="add-signature-button"
                    onClick={() => exportToPDF(file, pdfLinkOfPPTX, signatures)}
                    style={{ marginTop: '10px' }}
                    disabled={signatures.length === 0}
                  >
                    Send
                  </button>
                )
              }
              {adminEmailPdfUrl ? (<></>) : (
                <div class="checkbox-container">
                  <input checked={adminCheck}
                    onChange={handleCheckboxChange} type="checkbox" id="admin-signature" />
                  <label for="admin-signature">Signature by Admin</label>
                </div>
              )}
            </>
          )}
          {showFinishDocument && (
            <button
              className="send-button"
              disabled={!isValidSignature}
              onClick={() => handleFinishDocument()}
              style={{ marginTop: '10px' }}
            >
              Finish Document
            </button>
          )}
        </div>
        <div className="document-content" ref={drop}>
          <div
            ref={redBoxRef}
            className="pdf-viewer"
            style={{
              border: '1px solid #ddd',
              position: 'relative',
              paddingBottom: '10px',
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#EFEDEC",
            }}
          >
            {
              (queryPdfUrl || adminEmailPdfUrl || pdfLink || getFileType === 'application/pdf' || file) &&
              <PdfViewer
                pdfLink={pdfLink}
                fileUrl={fileUrl}
                signatures={signatures}
                currentSlideIndex={currentSlideIndex}
                viewerRef={viewerRef}
                setCurrentSlideIndex={setCurrentSlideIndex}
                recipientList={recipientList}
                file={file?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? "" : file}
                setSignatureFile={setSignatureFile}
                setCanvasBox={setCanvasBox}
                setSlideHeights={handleSlideHeights}
                setPdfFileUrl={setPdfFileUrl}
                setPdfLinkOfPPTX={setPdfLinkOfPPTX}
                queryPdfUrl={queryPdfUrl}
                adminEmailPdfUrl={adminEmailPdfUrl}
                activeRecipientEmail={activeRecipientEmail}
                setPdfLoad={setPdfLoad}
                pdfLoad={pdfLoad}
              />
            }
          </div>
          <div className="sidebar">
            <h3>Recipients</h3>
            <select
              value={selectedRecipient}
              onChange={(e) => setSelectedRecipient(e.target.value)}
              className="recipient-select"
              style={{
                color: recipientList.find((r) => r.email === selectedRecipient)?.color || "inherit",
              }}
            >
              {recipientList && recipientList.length > 0
                ? recipientList.map((recipient, index) => (
                  <option
                    key={index}
                    value={recipient.email}
                    style={{ color: recipient.color }}
                  >
                    {`${recipient.name} (${recipient.email})`}
                  </option>
                ))
                : (
                  <option value={activeRecipientEmail}>
                    {activeRecipientEmail || "No recipients available"}
                  </option>
                )}
            </select>
            {downloadDocument && (
              <button className="add-recipient-button" onClick={downloadSignedDocument}>Download Document</button>
            )}
            {finishDocument ? (
              <></>
            ) : (
              <>
                {adminEmailPdfUrl ? (<></>) : (
                  <button className="add-recipient-button" onClick={handleAddRecipient}>Add Recipient</button>

                )}
                <button
                  className="add-signature-button"
                  onClick={() => {
                    addSignature();
                  }}
                >
                  Add Signature
                </button>
                {
                  adminEmailPdfUrl ? (
                    <button
                      className="send-button"
                      onClick={() => exportAdminPDF(adminEmailPdfUrl, signatures, jsonFileToken)}
                      style={{ marginTop: '10px' }}
                      disabled={signatures.length === 0}
                    >
                      Send
                    </button>
                  ) : (
                    <button
                      className="send-button"
                      onClick={() => exportToPDF(file, pdfLinkOfPPTX, signatures)}
                      style={{ marginTop: '10px' }}
                      disabled={disabledButton}
                    >
                      Send
                    </button>
                  )
                }
                {adminEmailPdfUrl ? (<></>) : (
                  <div class="checkbox-container">
                    <input checked={adminCheck}
                      onChange={handleCheckboxChange} type="checkbox" id="admin-signature" />
                    <label for="admin-signature">Signature by Admin</label>
                  </div>
                )}
              </>
            )}
            {showFinishDocument && (
              <button
                className="send-button"
                disabled={!isValidSignature}
                onClick={() => handleFinishDocument()}
                style={{ marginTop: '10px' }}
              >
                Finish Document
              </button>
            )}
          </div>
        </div>
        {showRecipientModal && renderRecipientModal()}
        {isEditingSignature && renderSignaturePopup()}
      </div>
    </>
  );
};

export default DocumentPreview;
