import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ReviewRecipients.css';

const ReviewRecipients = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { recipients: initialRecipients = [], documentName, file, uniqueToken } = location.state || {};
  const [recipients, setRecipients] = useState(initialRecipients);
  const emails = recipients.map(recipient => recipient.email);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecipient, setCurrentRecipient] = useState({ name: '', email: '', phoneNumber: '' });
  const [editIndex, setEditIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const openModal = (recipient = { name: '', email: '', phoneNumber: '' }, index = null) => {
    setCurrentRecipient(recipient);
    setEditIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentRecipient({ name: '', email: '', phoneNumber: '' });
    setEditIndex(null);
    setErrorMessage("");
  };

  const handleRecipientChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecipient((prev) => ({ ...prev, [name]: value }));

    if (name === "email") {
      if (value === "gorillahashcompany@gmail.com") {
        setErrorMessage("This Email is not allowed");
        setDisabledButton(true);
      } else {
        setErrorMessage("");
        setDisabledButton(false);
      }
    } else {
      if (currentRecipient.email === "gorillahashcompany@gmail.com") {
        setDisabledButton(true);
      } else {
        setDisabledButton(false);
      }
    }
  };

  const handleSaveRecipient = () => {
    if (editIndex !== null) {
      const updatedRecipients = recipients.map((rec, i) => (i === editIndex ? currentRecipient : rec));
      setRecipients(updatedRecipients);
    } else {
      setRecipients([...recipients, currentRecipient]);
    }
    closeModal();
  };

  const handleDeleteRecipient = (index) => {
    setRecipients(recipients.filter((_, i) => i !== index));
  };

  const handleContinue = () => {
    navigate('/send-document', { state: { recipients, documentName, file, emails, uniqueToken, recipients } });
  };

  return (
    <div className="review-recipients-container">
      <h2>Confirm the recipients below before sending the document.</h2>
      <div className="document-info">
        <label>Document name *</label>
        <input type="text" value={documentName || 'Unnamed Document'} readOnly /> {/* Display dynamic document name */}
      </div>
      <div className="recipient-list">
        {recipients.map((recipient, index) => (
          <div className="recipient-card" key={index}>
            <div className="recipient-header">
              <div className="recipient-name">{recipient.email}</div>
              <button onClick={() => openModal(recipient, index)}>Edit contact</button>
            </div>
            <div className="recipient-options">
              <label>Document delivery</label>
              <input type="text" value="Email" readOnly />
              <label>Identity verification</label>
              <input type="text" value="No verification" readOnly />
            </div>
            <button className="delete-recipient" onClick={() => handleDeleteRecipient(index)}>
              Remove Recipient
            </button>
          </div>
        ))}
      </div>
      <button className="add-recipient-button" onClick={() => openModal()}>Add recipient</button>
      <button className="continue-button" onClick={handleContinue}>Continue</button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>{editIndex !== null ? 'Edit Recipient' : 'Add Recipient'}</h3>
            <input
              type="text"
              name="name"
              placeholder="Enter recipient name"
              value={currentRecipient.name}
              onChange={handleRecipientChange}
              className="input-field"
            />
            <input
              type="email"
              name="email"
              placeholder="Enter recipient email"
              value={currentRecipient.email}
              onChange={handleRecipientChange}
              className="input-field"
            />
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Enter phone number"
              value={currentRecipient.phoneNumber}
              onChange={handleRecipientChange}
              className="input-field"
            />
            {errorMessage && (<p style={{ fontSize: "14px", color: "red" }}>{errorMessage}</p>)}
            <button disabled={disabledButton} className="save-button" onClick={handleSaveRecipient}>Save</button>
            <button className="cancel-button" onClick={closeModal}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewRecipients;
