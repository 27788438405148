import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/webpack";

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const Viewer = ({
  pdfLink,
  fileUrl,
  file,
  setCurrentSlideIndex,
  viewerRef,
  setCanvasBox,
  setSlideHeights,
  setPdfFileUrl,
  setPdfLinkOfPPTX,
  queryPdfUrl,
  adminEmailPdfUrl,
  signatures,
  activeRecipientEmail,
  setPdfLoad,
}) => {
  const [fileContent, setFileContent] = useState(null);
  const [fileType, setFileType] = useState(null);
  const slideHeightsRef = useRef([]);
  const renderedOnceRef = useRef(false);

  setPdfFileUrl(pdfLink || fileUrl || file);

  const scrollToSlide = (slideIndex) => {
    const slides = viewerRef.current.querySelectorAll(".pdf-slide, .image-slide");
    if (slides[slideIndex]) {
      slides[slideIndex].scrollIntoView({ behavior: "smooth" });
      setCurrentSlideIndex(slideIndex);
    }
  };

  useEffect(() => {
    const moveToNextEditableSlide = () => {
      const currentSlide = signatures.find(
        (sig) =>
          sig.recipientEmail === activeRecipientEmail &&
          !sig.signatureIsEdit
      );

      if (!currentSlide) return;
      console.log("currentSlide", currentSlide)
      scrollToSlide(currentSlide.slideIndex);
    };

    moveToNextEditableSlide();
  }, [signatures, activeRecipientEmail]);

  useEffect(() => {
    const fetchFile = async (fileSource) => {
      try {
        const response = await fetch(fileSource);
        const blob = await response.blob();
        setFileContent(blob);
        setFileType(blob.type);
      } catch (error) {
        console.error("Failed to fetch file:", error);
      }
    };

    if (pdfLink) {
      fetchFile(pdfLink);
    } else if (file) {
      setFileContent(file);
      setFileType(file.type);
    } else if (fileUrl) {
      fetchFile(fileUrl);
    }
  }, [pdfLink, fileUrl, file]);

  useEffect(() => {
    if ((!fileContent && !queryPdfUrl && !adminEmailPdfUrl) || !viewerRef.current) return;

    const renderFile = async () => {
      if (renderedOnceRef.current) {
        return;
      }
      renderedOnceRef.current = true;
      const viewer = viewerRef.current;

      viewer.style.position = "relative";
      viewer.innerHTML = "";

      try {
        if (fileType === "application/pdf" || queryPdfUrl || adminEmailPdfUrl) {
          const pdfUrl = queryPdfUrl || adminEmailPdfUrl || (typeof fileContent === "string" ? fileContent : URL.createObjectURL(fileContent));
          setPdfLinkOfPPTX(pdfUrl);

          const loadingTask = pdfjsLib.getDocument(pdfUrl);
          const pdf = await loadingTask.promise;

          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);


            const canvasContainer = document.createElement("div");
            setCanvasBox?.(canvasContainer);
            canvasContainer.style.position = "relative";
            canvasContainer.style.marginBottom = "40px";
            canvasContainer.className = "pdf-slide";
            canvasContainer.dataset.slideIndex = pageNum - 1;

            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.style.width = "100%";
            canvas.style.height = "auto";
            canvasContainer.appendChild(canvas);
            viewer.appendChild(canvasContainer);

            const viewport = page.getViewport({ scale: 1.0 });
            const scale = viewer.offsetWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale });
            const dpr = window.devicePixelRatio || 1;

            canvas.width = scaledViewport.width * dpr;
            canvas.height = scaledViewport.height * dpr;
            context.scale(dpr, dpr);

            await page.render({
              canvasContext: context,
              viewport: scaledViewport,
            }).promise;


          }

          const firstRelevantSlide = signatures.find(
            (sig) => sig.recipientEmail === activeRecipientEmail && !sig.signatureIsEdit
          )?.slideIndex;
          
          console.log("firstRelevantSlide",firstRelevantSlide)
          if (firstRelevantSlide !== undefined) {
            scrollToSlide(firstRelevantSlide);
          }
          setPdfLoad(true);
        } else if (["image/jpeg", "image/png", "image/jpg"].includes(fileType)) {
          const imgUrl = URL.createObjectURL(fileContent);
          setPdfLinkOfPPTX("imageHave");
          const imgContainer = document.createElement("div");
          setCanvasBox?.(imgContainer);
          imgContainer.style.position = "relative";
          imgContainer.style.marginBottom = "40px";
          imgContainer.className = "image-slide";

          const img = document.createElement("img");
          img.src = imgUrl;
          img.style.display = "block";
          img.style.maxWidth = "100%";
          img.style.height = "auto";
          img.style.width = "100%"
          imgContainer.appendChild(img);
          viewer.appendChild(imgContainer);

          setPdfLoad(true);

        }
      } catch (error) {
        console.error("Error rendering file:", error);
        setPdfLoad(false);
      } finally {
        console.log("Finished rendering");
      }
    };

    renderFile();
  }, [fileContent, fileType, queryPdfUrl, viewerRef, setCanvasBox, setPdfLinkOfPPTX]);

  useEffect(() => {
    if (!viewerRef.current) return;

    const calculateSlideHeights = () => {
      const slides = viewerRef.current.querySelectorAll(".pdf-slide, .image-slide");
      const heights = Array.from(slides).map((slide) => slide.offsetHeight);

      if (JSON.stringify(slideHeightsRef.current) !== JSON.stringify(heights)) {
        slideHeightsRef.current = heights;
        setSlideHeights?.(heights);
      }
    };

    calculateSlideHeights();
    const observer = new MutationObserver(calculateSlideHeights);
    observer.observe(viewerRef.current, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [viewerRef, setSlideHeights]);

  const handleScroll = (e) => {
    const slides = viewerRef.current.querySelectorAll(".pdf-slide, .image-slide");
    const scrollTop = e.target.scrollTop;
    const containerHeight = e.target.offsetHeight;

    slides.forEach((slide, index) => {
      const slideTop = slide.offsetTop - viewerRef.current.offsetTop;
      const slideBottom = slideTop + slide.offsetHeight;

      if (
        scrollTop + containerHeight / 2 >= slideTop &&
        scrollTop + containerHeight / 2 < slideBottom
      ) {
        setCurrentSlideIndex?.(index);
      }
    });
  };

  return (
    <div
      className="boxWidth"
      style={{
        width: "85%",
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        backgroundColor: "#EFEDEC",
        scrollbarWidth: "none",
      }}
      ref={viewerRef}
      onScroll={handleScroll}
    />
  );
};

export default Viewer;
