import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./DocumentList.css"
const DocumentList = () => {
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        const response = await fetch("https://myreact29bucket.s3.eu-north-1.amazonaws.com/Admin/AdminDetail.json");
        if (!response.ok) {
          throw new Error(`Failed to fetch the document: ${response.statusText}`);
        }

        const data = await response.json();

        const filteredDocuments = data.filter(doc => doc.adminCheck === "true");

        setDocuments(filteredDocuments);
        setFilteredDocuments(filteredDocuments);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
        setError("Failed to fetch documents. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchJsonData();
  }, []);

  const handleDocumentClick = (documentName, emailPdfUrl, token) => {
    navigate("/sign", { state: { adminDocumentName: documentName, adminEmailPdfUrl: emailPdfUrl, jsonFileToken: token } });
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = documents.filter(doc =>
      doc.documentName.toLowerCase().includes(query)
    );
    setFilteredDocuments(filtered);
  };

  return (
    <div 
    className='box-width'>
      <h1>Document List</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", overflowY: "auto", padding: "10px 0px", scrollbarWidth: "none" }}>
          <input
            style={{
              width: "100%",
              padding: "10px",
              boxSizing: "border-box",
              border: "none",
              borderBottom: "2px solid #ccc"
            }}
            placeholder="Search Here"
            value={searchQuery}
            onChange={handleSearch}
          />
          <div style={{ marginTop: "20px" }}>
            {filteredDocuments.length > 0 ? (
              filteredDocuments.map((doc, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    padding: "20px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                  onClick={() => handleDocumentClick(doc.documentName, doc.emailPdfUrl, doc.token)}
                >
                  <p style={{ margin: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <strong>Document Name:</strong> {doc.documentName}
                  </p>
                </div>
              ))
            ) : (
              <p>No documents found.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentList;
